unit UnitTreatment;

interface

uses

  System.SysUtils,
  System.Classes,
  System.Math,
  System.Types,
  System.DateUtils,
  Generics.Collections,

  JSDelphiSystem,
  JS,
  Web,

  XData.Web.Client,
  XData.Web.Connection,

  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls,
  WEBLib.WebTools,
  WEBLib.JSON,

  Vcl.Controls,
  Vcl.StdCtrls,
  VCL.TMSFNCCustomControl,
  VCL.TMSFNCWebBrowser,
  VCL.TMSFNCCustomWEBControl,
  WEBLib.DropDown,
  WEBLib.ExtCtrls,
  WEBLib.ComCtrls,

  Types_Vars_API,
  Types_Vars_Security;

type

  TFormTreatment = class(TForm)

    divTreatment: THTMLDiv;
    divTreatmentContainer: THTMLDiv;
    spanTitle: THTMLSpan;

    [async] procedure GetServerPath; async;
    [async] procedure GetTreatments; async;
    [async] procedure GetStartAndEndHours; async;
    [async] procedure GetProviders; async;
    [async] procedure WebFormCreate(Sender: TObject); async;

  private

    { Private declarations }

  public

    { Public declarations }

    ConnectionString, FailedConnectionString: String;
    Sequence, Port, OfficeSequence: Integer;
    GlobalServer: Boolean;
    Treatments, SelectedTreatment, StartHour, EndHour: JSValue;
    OfficeProviders: TclsOfficeProviders;

  protected procedure LoadDFMValues; override; end;

var
  FormTreatment: TFormTreatment;

implementation

{$R *.dfm}

uses
  UnitMain;

// Retrieve providers from the JSON Response:
function ProvidersFromJSON(asJSON: string; aiOffSeq: integer): TclsOfficeProviders;
var
  jsProvider: TJSObject;
  jsAryProviders: TJSArray;
  tProvider: TclsApptSchedProvider;
  i: Integer;
begin
  Result := TclsOfficeProviders.Create;
  Result.lstOfficeProviders := tlstProviders.Create;
  try
    jsAryProviders := TJSArray(TJSObject(TJSJSON.parse(asJSON))['value']);
    for i := 0 to jsAryProviders.Length - 1  do
    begin
      tProvider := TclsApptSchedProvider.Create;
      jsProvider := TJSObject(jsAryProviders[i]);

      With tProvider Do
      begin
        IsActive := StrToBool(string(jsProvider[gcsIsActive]));
        ColumnSequence := StrToInt(string(jsProvider[gcsColumnSequence]));
        Provider := string(jsProvider[gcsProvider]);
        Name := string(jsProvider[gcsName]);
        ShortName := string(jsProvider[gcsShortName]);
        IsHygienist := StrToBool(string(jsProvider[gcsIsHygienist]));
        ColumnColor := StrToInt(string(jsProvider[gcsColumnColor]));
        OfficeSequence := aiOffSeq;
      end;

      Result.lstOfficeProviders.Add(tProvider);
    end;
  finally
    tProvider.Free;
  end;
end;

procedure TFormTreatment.GetServerPath;
var
  CurrentForm: TFormTreatment;
  HTTPRequest: TclsLogicHTTPRequest;
  req: TJSXMLHttpRequest;
  Office: TclsOfficeInfo;
begin
  CurrentForm := Self;
  HTTPRequest := TclsLogicHTTPRequest.Create(nil);
  try
    HTTPRequest.URL := './JS/Path.json';
    req := await(TJSXMLHttpRequest, HTTPRequest.Perform());
    if req.Status = 200 then
    begin
      {$IFNDEF WIN32}
      asm
        const data = JSON.parse(req.response);
        this.ConnectionString = data.URL;
        this.Port = data.Port;
      end;
      {$ENDIF}
    end
    else
    begin
      // Error Handling:
      HTTPRequest.ProcessResponseError(String(req.response));
    end;
  finally
    HTTPRequest.Free;
  end;
end;

// Retrieve treatments from the server:
procedure TFormTreatment.GetTreatments;
var
  HTTPRequest: TclsLogicHTTPRequest;
  req: TJSXMLHttpRequest;
  Office: TclsOfficeInfo;
begin
  HTTPRequest := TclsLogicHTTPRequest.Create(nil);
  Office := TclsOfficeInfo.Create;
  Office.ConnectionString := ConnectionString;
  Office.Port := Port;
  try
    HTTPRequest.URL := 'https://registration.intellibooking.ca:' + IntToStr(2011) + '/Data/IB/GetOfficeTreatments?OfficeID=A456A8EF-579D-4E61-A106-2140D18F8EB2';
    HTTPRequest.AddHeaders(1,1,1,'booking', 'booking');
    req := await(TJSXMLHttpRequest, HTTPRequest.Perform());
    if req.Status = 200 then
    begin
      {$IFNDEF WIN32}
      asm
        this.Treatments = JSON.parse(req.response);
        this.OfficeSequence = this.Treatments.OfficeSequence;
      end;
      {$ENDIF}
    end
    else
    begin
      // Error Handling:
      HTTPRequest.ProcessResponseError(String(req.response));
    end;
  finally
    HTTPRequest.Free;
  end;
end;

// Get the Start and End hours for a specific office, default to 9:00am and 7:00pm otherwise:
procedure TFormTreatment.GetStartAndEndHours;
var
  HTTPRequest: TclsLogicHTTPRequest;
  req: TJSXMLHttpRequest;
  Office: TclsOfficeInfo;
begin
  HTTPRequest := TclsLogicHTTPRequest.Create(nil);
  Office := TclsOfficeInfo.Create;
  Office.OfficeSequence := OfficeSequence;
  Office.UserID := 1;
  Office.APIToken := 'Yup, this is totally a real token';
  Office.APIPassword := 'ThoronWeilder11';
  Office.ConnectionString := ConnectionString;
  Office.Port := Port;
  try
    HTTPRequest.AddHeaders(Office.OfficeSequence, Office.UserID,
      gciModuleIntellieForm, Office.APIToken, Office.APIPassword);
    HTTPRequest.URL := Office.ConnectionString + IntToStr(Office.Port) + '/Data/appointments/schedule/BookSetup';
    req := await(TJSXMLHttpRequest, HTTPRequest.Perform());
    if req.Status = 200 then
    begin
      {$IFNDEF WIN32}
      asm
        const data = JSON.parse(req.response);
        this.StartHour = data.StartHour;
        this.EndHour = data.EndHour;
      end;
      {$ENDIF}
    end
    else
    begin
      // Error Handling:
      HTTPRequest.ProcessResponseError(String(req.response));
    end;
  finally
    HTTPRequest.Free;
  end;
end;

// Get the providers for this office sequence:
procedure TFormTreatment.GetProviders;
var
  HTTPRequest: TclsLogicHTTPRequest;
  req: TJSXMLHttpRequest;
  aryOffices: TList<tclsOfficeInfo>;
  Office: TclsOfficeInfo;
  iOfficeIndex: Integer;
begin
  HTTPRequest := TclsLogicHTTPRequest.Create(nil);
  OfficeProviders := TclsOfficeProviders.Create;
  aryOffices := TList<tclsOfficeInfo>.Create;
  iOfficeIndex := 0;

  Office := TclsOfficeInfo.Create;
  Office.OfficeSequence := OfficeSequence;
  Office.UserID := 1;
  Office.APIToken := 'Yup, this is totally a real token';
  Office.APIPassword := 'ThoronWeilder11';
  Office.ConnectionString := ConnectionString;
  Office.Port := Port;
  aryOffices.Add(Office);
  try
	// also uses Types_Vars_API to generate HTTP request URL
    HTTPRequest.SetConnectionString(aryOffices[iOfficeIndex],
      gcsProvidersRequest);
    HTTPRequest.AddHeaders(aryOffices.Items[iOfficeIndex].OfficeSequence,
      aryOffices[iOfficeIndex].UserID, gciModuleIntelliOffice,
      aryOffices[iOfficeIndex].APIToken, aryOffices[iOfficeIndex].APIPassword);
    req := await(TJSXMLHttpRequest, HTTPRequest.Perform());
    if req.Status = 200 then
    begin
      OfficeProviders := ProvidersfromJSON(String(req.response),
        aryOffices[iOfficeIndex].OfficeSequence);
    end
    else
    begin
//      HTTPRequest.ProcessResponseError(string(req.response));
      GlobalServer := True;
      Sequence := Office.OfficeSequence;
      FailedConnectionString := ConnectionString;
      ConnectionString := 'http://72.137.210.34:';
      OfficeProviders := ProvidersfromJSON('{"value": []}', aryOffices[iOfficeIndex].OfficeSequence);
    end;
  finally
    HTTPRequest.Free;
  end;
end;

// Check for a URL parameter, and add treatment types to the div, find office name, and find available time slots:
procedure TFormTreatment.WebFormCreate(Sender: TObject);
var
  CurrentForm: TFormTreatment;
begin
  CurrentForm := Self;
  if GetQueryParam('Office') <> '' then
  else
  begin
    {$IFNDEF WIN32}
    asm
      // Scroll to the top of the form:
      $("html").scrollTop(0);
      $("body").scrollTop(0);
      await this.GetServerPath();
      await this.GetTreatments();
      await this.GetProviders();
      await this.GetStartAndEndHours();
      const providers = this.OfficeProviders.lstOfficeProviders.FItems.filter(provider => provider.IsActive);
      const treatments = this.Treatments;
      const id = treatments.$id;
      const interval = treatments.Interval;
      const office = treatments.OfficeName;
      const startHour = this.StartHour;
      const endHour = this.EndHour;
      const port = this.Port;
      $("#divTreatment").css("opacity", "1");
      const treatmentData = JSON.parse(treatments.TreatmentJSON);
      treatmentData.forEach((treatment, i) => {
        $(`#divTreatmentContainer`).append(`
          <div id="treatment-${i + 1}" class="treatment-holder">
            ${treatment.icon}
            <h3 align="center">${treatment.name}</h3>
            <p align="center">${treatment.description}</p>
          </div>
        `);
        $(`#treatment-${i + 1}`).on("click", event => {
          event.preventDefault();
          $(".treatment-holder").removeClass("selected");
          $(`#treatment-${i + 1}`).addClass("selected");
          this.SelectedTreatment = {
            id,
            name: treatment.name,
            office,
            providers,
            unit: treatment.units,
            interval,
            startHour,
            endHour,
            port,
          };
          if (this.GlobalServer) {
            this.SelectedTreatment.OfficeSequence = this.Sequence;
            this.SelectedTreatment.failedConnectionString = this.FailedConnectionString;
          }
          pas.UnitMain.FormMain.LoadAppointmentForm(this.ConnectionString, this.FailedConnectionString, this.OfficeSequence, this.Port, this.GlobalServer, this.SelectedTreatment);
        });
      });
    end;
    {$ENDIF}

  end;
end;

procedure TFormTreatment.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divTreatment := THTMLDiv.Create('divTreatment');
  spanTitle := THTMLSpan.Create('spanTitle');
  divTreatmentContainer := THTMLDiv.Create('divTreatmentContainer');

  divTreatment.BeforeLoadDFMValues;
  spanTitle.BeforeLoadDFMValues;
  divTreatmentContainer.BeforeLoadDFMValues;
  try
    Name := 'FormTreatment';
    Width := 1225;
    Height := 764;
    CSSLibrary := cssBootstrap;
    ElementClassName := 'form';
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divTreatment.SetParentComponent(Self);
    divTreatment.Name := 'divTreatment';
    divTreatment.Left := 8;
    divTreatment.Top := 8;
    divTreatment.Width := 1209;
    divTreatment.Height := 748;
    divTreatment.ElementClassName := 'treatment';
    divTreatment.HeightStyle := ssAuto;
    divTreatment.WidthStyle := ssAuto;
    divTreatment.ElementPosition := epIgnore;
    divTreatment.ElementFont := efCSS;
    divTreatment.Role := '';
    spanTitle.SetParentComponent(divTreatment);
    spanTitle.Name := 'spanTitle';
    spanTitle.Left := 32;
    spanTitle.Top := 16;
    spanTitle.Width := 1113;
    spanTitle.Height := 41;
    spanTitle.ElementClassName := 'title';
    spanTitle.HeightStyle := ssAuto;
    spanTitle.WidthStyle := ssAuto;
    spanTitle.ElementPosition := epIgnore;
    spanTitle.ElementFont := efCSS;
    spanTitle.HTML.BeginUpdate;
    try
      spanTitle.HTML.Clear;
      spanTitle.HTML.Add('<h3>What are you looking for? Click one.</h3>');
    finally
      spanTitle.HTML.EndUpdate;
    end;
    spanTitle.Role := '';
    divTreatmentContainer.SetParentComponent(divTreatment);
    divTreatmentContainer.Name := 'divTreatmentContainer';
    divTreatmentContainer.Left := 13;
    divTreatmentContainer.Top := 104;
    divTreatmentContainer.Width := 1180;
    divTreatmentContainer.Height := 265;
    divTreatmentContainer.ElementClassName := 'treatment-container';
    divTreatmentContainer.HeightStyle := ssAuto;
    divTreatmentContainer.WidthStyle := ssAuto;
    divTreatmentContainer.ChildOrder := 1;
    divTreatmentContainer.ElementPosition := epIgnore;
    divTreatmentContainer.ElementFont := efCSS;
    divTreatmentContainer.Role := '';
  finally
    divTreatment.AfterLoadDFMValues;
    spanTitle.AfterLoadDFMValues;
    divTreatmentContainer.AfterLoadDFMValues;
  end;
end;

end.
