program Booking;

uses
  Vcl.Forms,
  WEBLib.Forms,
  UnitMain in 'UnitMain.pas'{*.html},
  UnitTreatment in 'UnitTreatment.pas'{*.html},
  UnitAppointment in 'UnitAppointment.pas'{*.html},
  Types_Vars_API in 'Types_Vars_API.pas',
  Types_Vars_Security in 'Types_Vars_Security.pas',
  UnitPatient in 'UnitPatient.pas'{*.html},
  UnitConfirm in 'UnitConfirm.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TFormMain, FormMain);
  Application.CreateForm(TFormTreatment, FormTreatment);
  Application.CreateForm(TFormAppointment, FormAppointment);
  Application.CreateForm(TFormPatient, FormPatient);
  Application.CreateForm(TFormConfirm, FormConfirm);
  Application.Run;
end.
