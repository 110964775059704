unit Types_Vars_Security;

interface

Type
  tOptionsStatus = (tOptionsUnDefined, tOptionsEnabled, tOptionsDisabled);
  tDisplayNameFormat = (tDisplayNameFull, tDisplayNameBlank, tDisplayNameWithX, tDisplayNameFirst);
  tDisplayApptFormat = (tDisplayApptAll, tDisplayApptDescriptionOnly, tDisplayApptDescriptionAndPhones);

Const
  gciSecurityAppointment = 1000;
  gciSecurityBooking = gciSecurityAppointment + 1;
  gciSecurityAccounting = 1500;
  gciSecurityPatient = 2000;
  gciSecurityBilling = 2500;
  gciSecurityClinical = 3000;
  gciSecurityConfiguration = 3500;
  gciSecurityReport = 4000;
  //
  // { UserGroups.OptionsAllowed  only
  //
  gciDisplayDashBoardInMainScreen = 1;
  gciModuleIntelliOffice = 11;
  gciModuleIntelliDoctor = 12;
  gciModuleIntellieForm = 13;
  gciModuleIntelliSurvey = 14;
  gciModuleIntelliRecall = 15;
  gciModuleAdmin = 16;
  gciModuleDashBoard = 17;
  gciModuleRestServer = 18;
  gciIsAddAllowed = 31;
  gciIsDeleteAllowed = 32;
  gciIsModifyAllowed = 33;
  gciIsPrintAllowed = 34;
  //
  // Apply to Both IO_OptionsAllowed and ID_OptionsAllowed
  gciOptionsDisplayPatientInfo = 1;
  gciOptionsDisplayPhoneNumbers = 2;
  gciOptionsDisplayCoverages = 3;
  gciOptionsDisplayAddresses = 4;
  gciOptionsDisplayPatientNameFormat = 5; // tDisplayNameFormat
  //
  gciOptionsDisplayApptFormat = 11; // tDisplayApptFormat
  gciOptionsDisplayApptPatientNameFormat = 12; // tDisplayNameFormat
  gciOptionsDisplayPatientGridInApptBook = 13;
  //
  gciOptionsLedgerEnabled = 21;
  gciOptionsApptProfileEnabled = 41;
  gciOptionsTreatmentEntryEnabled = 51;
  gciOptionsReportsEnabled = 61;
  gciOptionsClinicalEnabled = 71;

type
  TSecurityAction = (tSecNone, tSecExecute, tSecExecuteAdminOnly, tSecDelete, tSecInsert, tSecEdit);
  tPermissionString = String;

  tclsConnections = class
    OfficeName: String;
    OfficeSequence: String;
    OfficeGuid: tGuid;
    IP: String;
    Port: String;
  end;

  tclsLoginReturn = class
    IsSucessfully: Boolean;
    OfficeSequence: Integer;
    UserID: Integer;
    GroupID: Integer;
    APIToken: String; // //  will be use by third parties to connect to rest server
    APIHashedPassword: String; // will be use by third parties to connect to rest server
    RestServerVersion: Integer; // export current rest server version
    RestServerDataVersion: Integer; // export current rest server Data version
    IsAdmin: Boolean;
    OptionsAllowed: String;
    IO_OptionsAllowed: String;
    ID_OptionsAllowed: String;

  end;

  tclsUser = class
    UserID: Integer;
    UserName: String;
    IsActive: Boolean;
    Guid: tGuid;
    GroupID: Integer;
    ProviderRange: String;
    OptionsAllowed: String;
    IsEnforceWorkTime: Boolean;
    DateRange: String;
    StartTime: String;
    EndTime: String;
    LastPasswordChanged: tDateTime;
    LastFailedLogin: tDateTime;
    NoOfFailedLogin: Integer;
  end;

  tclsUserGroup = class
    GroupID: Integer;
    GroupName: String;
    IsActive: Boolean;
    IsAdmin: Boolean;
    IO_OptionsAllowed: String;
    ID_OptionsAllowed: String;
    OptionsAllowed: String;
    Guid: tGuid;
  end;

  tUserGroupPermission = record
    GroupID: Integer;
    IsActive: Boolean;
    IsAdmin: Boolean;
    OptionsAllowed: tPermissionString;
    IO_OptionsAllowed: tPermissionString;
    ID_OptionsAllowed: tPermissionString;
    FromModule: Integer;
  end;

  tclsPermissions = class
    IsDeny: Boolean;
    IsInsertAllowed: Boolean;
    IsModifyAllowed: Boolean;
    IsDeleteAllowed: Boolean;
    IsPrintAllowed: Boolean;
    Permissions: String;
  end;

  tProgramPermission = class(tclsPermissions)
    GroupID: Integer;
    ProgramID: Integer;
    IsPasswordProtected: Boolean;
  end;

Var
  giCurrentUserID: Integer; // store current login user id

implementation


end.
