unit UnitAppointment;

interface

uses

  System.SysUtils,
  System.Classes,
  System.Math,
  System.Types,
  System.DateUtils,
  Generics.Collections,

  JSDelphiSystem,
  JS,
  Web,

  XData.Web.Client,
  XData.Web.Connection,

  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls,
  WEBLib.WebTools,
  WEBLib.JSON,
  WEBLib.REST,

  Vcl.Controls,
  Vcl.StdCtrls,
  VCL.TMSFNCCustomControl,
  VCL.TMSFNCWebBrowser,
  VCL.TMSFNCCustomWEBControl,
  WEBLib.DropDown,
  WEBLib.ExtCtrls,
  WEBLib.ComCtrls,

  Types_Vars_API,
  Types_Vars_Security;

type

  TFormAppointment = class(TForm)

    divAppointment: THTMLDiv;
    divBooking: THTMLDiv;
    divCalendarHolder: THTMLDiv;
    divAppointHolder: THTMLDiv;
    divProvider: THTMLDiv;
    divAppoint: THTMLDiv;
    divSelectProvider: THTMLDiv;
    divSelectedTime: THTMLDiv;
    btnConfirm: TButton;
    divDateTime: THTMLDiv;
    divDuration: THTMLDiv;
    spanDateTimeHeader: THTMLSpan;
    spanDateTime: THTMLSpan;
    spanDurationHeader: THTMLSpan;
    spanDuration: THTMLSpan;
    spanCalendarTitle: THTMLSpan;
    divCalendar: THTMLDiv;
    spanAppointTitle: THTMLSpan;
    divAppointInfo: THTMLDiv;
    divBefore12: THTMLDiv;
    div12to5: THTMLDiv;
    divAfter5: THTMLDiv;
    divTreatment: THTMLDiv;
    spanOffice: THTMLSpan;
    spanTreatment: THTMLSpan;
    spanBefore12Title: THTMLSpan;
    span12to5Title: THTMLSpan;
    spanAfter5Title: THTMLSpan;
    divBefore12Holder: THTMLDiv;
    div12to5Holder: THTMLDiv;
    divAfter5Holder: THTMLDiv; 
    spanBack: THTMLSpan;
    divSmallConfirm: THTMLDiv;
    spanSmallBack: THTMLSpan;
    btnNext: TButton;
    divLoading: THTMLDiv;
    spanLoader: THTMLSpan;
    spanLoadText: THTMLSpan;

    procedure spanBackClick(Sender: TObject);
    procedure spanSmallBackClick(Sender: TObject);
    procedure btnNextClick(Sender: TObject);
    procedure btnConfirmClick(Sender: TObject);

    [async] procedure GetTimeSlots(StartDate, EndDate: String; Provider: JSValue); async;
    procedure ParseTimeSlots(Day, Provider: JSValue);

    procedure WebFormCreate(Sender: TObject);

  private

    { Private declarations }

  public

    { Public declarations }

    ConnectionString: String;
    SelectDay, SelectTime, GlobalServer, InitialLoad: Boolean;
    MonthTimes, EmptyDays, SelectedTreatment, SelectedDate, StartDate, EndDate, SelectedTime, SelectedProvider, ProviderCodes, RefreshDate, TimeSlots, Calendar: JSValue;

  protected procedure LoadDFMValues; override; end;

var
  FormAppointment: TFormAppointment;

implementation

{$R *.dfm}

uses
  UnitMain;

// Return back to the treatment page:
procedure TFormAppointment.spanBackClick(Sender: TObject);
begin
  if (Width > 600) then FormMain.LoadForm('FormTreatment')
  else
  {$IFNDEF WIN32}
  asm
    $("#divAppoint").removeClass("show");
  end;
  {$ENDIF}
end;

// Go Back to the treatments page:
procedure TFormAppointment.spanSmallBackClick(Sender: TObject);
begin
  {$IFNDEF WIN32}
  asm
    if ($("#divAppointHolder").hasClass("show")) {
      $("#divAppointHolder").removeClass("show");
      $("#divProvider").removeClass("hide");
      $("#divCalendarHolder").removeClass("hide");
      $(".slot").removeClass("selected");
      this.btnNext.SetEnabled(true);
      $("#btnNext").removeClass("disabled");
      $("#btnNext").html("Select Date");
      return;
    }
  end;
  {$ENDIF}
  FormMain.LoadForm('FormTreatment');
end;

// Smaller Screens only: Close the calendar and timeslot divs and move onto the providers page:
procedure TFormAppointment.btnNextClick(Sender: TObject);
begin
  btnNext.Enabled := False;
  {$IFNDEF WIN32}
  asm
    if (!$("#divAppointHolder").hasClass("show")) {
      $("#divProvider").addClass("hide");
      $("#divCalendarHolder").addClass("hide");
      $("#divAppointHolder").addClass("show");
      this.btnNext.SetEnabled(false);
      $("#btnNext").addClass("disabled");
      $("#btnNext").html("Confirm");
      $("#btnNext").html("Select Time");
      $("html").scrollTop(0);
      $("body").scrollTop(0);
      return;
    }
  end;
  {$ENDIF}
  btnConfirmClick(nil);
end;

// For now just move onto the patient info page:
procedure TFormAppointment.btnConfirmClick(Sender: TObject);
begin
  // Move to patient info page:
  {$IFNDEF WIN32}
  asm
    let provider;
    provider = this.SelectedTreatment.providers.find(provider => provider.Provider === this.SelectedProvider);
    if (this.GlobalServer) {
      provider = {
        Name: "Any Provider",
        Provider: "1",
      };
    }
    const appointment = {
      treatment: this.SelectedTreatment,
      day: this.RefreshDate,
      time: this.SelectedTime,
      provider,
      connectionString: this.ConnectionString,
    };
    if (this.GlobalServer) {
      appointment.OfficeSequence = this.SelectedTreatment.OfficeSequence;
      appointment.connectionString = this.SelectedTreatment.ConnectionString;
    }
    pas.UnitMain.FormMain.LoadPatientForm(appointment, this.SelectedDate);
  end;
  {$ENDIF}
end;

// Get the providers for this office sequence:
procedure TFormAppointment.GetTimeSlots(StartDate, EndDate: String; Provider: JSValue);
var
  HTTPRequest: TclsLogicHTTPRequest;
  CurrentForm: TFormAppointment;
  req: TJSXMLHttpRequest;
  Office: TclsOfficeInfo;
  Weekdays: TJSONArray;
  PostData: String;
begin
  CurrentForm := Self;
  HTTPRequest := TclsLogicHTTPRequest.Create(nil);
  Office := TclsOfficeInfo.Create;
  Office.OfficeSequence := FormMain.OfficeSequence;
  Office.UserID := 1;
  Office.APIToken := 'Yup, this is totally a real token';
  Office.APIPassword := 'ThoronWeilder11';
  Office.ConnectionString := ConnectionString;
  Office.Port := FormMain.ServerPort;
  HTTPRequest.Command := httpPut;
  {$IFNDEF WIN32}
  asm
    const provider = Provider.join(",");
    const WeekDaysToSearch = ["tWeekDayEveryDay"];
    const SearchUnit = this.SelectedTreatment.unit;
    const StartSearchHour = this.SelectedTreatment.startHour;
    const ToSearchHour = this.SelectedTreatment.endHour;
    PostData = JSON.stringify({
      StartingDate: StartDate,
      EndingDate: EndDate,
      WeekDaysToSearch,
      SearchType: 'tProviderAllActive',
      DoctorStr: provider,
      StartSearchHour,
      ToSearchHour,
      SearchUnit,
      NumberOfOpenningsToSearch: 1000,
      IsSameDay: true,
      IsContinous: true,
    });
  end;
  {$ENDIF}
  HTTPRequest.PostData := PostData;
  try
    HTTPRequest.Headers.AddPair('Content-Type', 'application/json');
    HTTPRequest.AddHeaders(Office.OfficeSequence, Office.UserID,
      gciModuleIntellieForm, Office.APIToken, Office.APIPassword);
    HTTPRequest.URL := ConnectionString + IntToStr(Office.Port) + '/Data/appointments/SearchOpennings';
    req := await(TJSXMLHttpRequest, HTTPRequest.Perform());
    if req.Status = 200 then
    begin
      // Parse data for timeslots here:
      {$IFNDEF WIN32}
      asm
        this.MonthTimes = JSON.parse(req.response).value;
        this.EmptyDays = [];
        let first = StartDate.split("-");
        first[0] = first.splice(2, 1, first[0])[0];
        first[0] = first.splice(1, 1, first[0])[0];
        first = first.join("/");
        let last = EndDate.split("-");
        last[0] = last.splice(2, 1, last[0])[0];
        last[0] = last.splice(1, 1, last[0])[0];
        last = last.join("/");
        for (let d = new Date(first); d <= new Date(last); d.setDate(d.getDate() + 1)) {
          const day = d.toISOString().slice(0, 10);
          let timesAvailable = false;
          this.MonthTimes.forEach(time => {
            if (time.AppointDate === day) {
              timesAvailable = true;
            }
          });
          if (!timesAvailable) {
            this.EmptyDays.push(day.split("-")[2]);
            this.EmptyDays.forEach((day, index) => {
              day[0] === "0" ? day = day.substring(1) : day;
              this.EmptyDays[index] = day;
            });
          }
        }
        this.ParseTimeSlots(this.RefreshDate, this.ProviderCodes);
        this.InitialLoad = false;
        this.Calendar.unfreeze();
        $(".icon").prop("disabled", false);
      end;
      {$ENDIF}
    end
    else
    begin
      // Error Handling:
      HTTPRequest.ProcessResponseError(String(req.response));
      {$IFNDEF WIN32}
      asm
        this.Calendar.unfreeze();
      end;
      {$ENDIF}
    end;
  finally
    HTTPRequest.Free;
  end;
end;

// Parse and append every timeslot for the selected day:
procedure TFormAppointment.ParseTimeSlots(Day, Provider: JSValue);
var
  CurrentForm: TFormAppointment;
begin
  CurrentForm := Self;
  {$IFNDEF WIN32}
  asm
    debugger;
    const slots = [];
    const timeInfo = [];
    const providers = [];
    this.SelectedTreatment.providers.forEach(provider => {
      providers.push({
        name: provider.Name,
        provider: provider.Provider,
        times: [],
      });
    });
    this.MonthTimes.forEach(time => {
      if (time.AppointDate === Day) {
        if (Provider.length === 1 && time.provider === Provider[0]) {
          slots.push(time.TimeSlot);
          timeInfo.push({
            time: time.TimeSlot,
            provider: time.provider,
          });
        } else if (Provider.length > 1) {
          slots.push(time.TimeSlot);
          timeInfo.push({
            time: time.TimeSlot,
            provider: time.provider,
          });
        }
      }
    });

    // Check if the office connection failed. If so, hardcode the timeslots:
    if (this.GlobalServer) {
      const interval = this.SelectedTreatment.interval;
      if (interval === 15) {
        let hour = 9;
        let minute = "00";
        let time;
        for (let i = 1; i < 41; i++) {
          time = `${hour}:${String(minute)}`;
          slots.push(time);
          if (typeof minute === "string") minute = Number(minute);
          minute += interval;
          if (Number(minute) === 60) {
            hour++;
            minute = "00";
          }
        }
        if (interval === 10) {
          let hour = 9;
          let minute = "00";
          let time;
          for (let i = 1; i < 61; i++) {
            time = `${hour}:${String(minute)}`;
            slots.push(time);
            if (typeof minute === "string") minute = Number(minute);
            minute += interval;
            if (Number(minute) === 60) {
              hour++;
              minute = "00";
            }
          }
        }
      }
    }

    // Sort each timeslot chronologically:
    slots.sort((a, b) => {
      const aSplit = a.split(":");
      const bSplit = b.split(":");
      if (aSplit[0] === bSplit[0]) {
        return Number(aSplit[1] - bSplit[1]);
      }
      return Number(Number(aSplit[0] - bSplit[0]));
    });

    providers.forEach(provider => {
      timeInfo.forEach(slot => {
        if (slot.provider === provider.provider) {
          provider.times.push(slot.time);
        }
      });
    });

    if (this.SelectedProvider === "none") {
      $("#providers").empty();
      $("#providers").append(`
        <option id="provider-placeholder" value="none">
          All Providers
        </option>
      `);
      providers.forEach(provider => {
        $("#providers").append(`
          <option id="provider-${provider.provider}" value="${provider.provider}" class=${provider.times.length > 0 ? "" : "grey"}>
            ${provider.name}  [${provider.provider}]
          </option>
        `);
      });
    } else {
      providers.forEach(provider => {
        if (provider.times.length > 0) $(`#provider-${provider.provider}`).removeClass("grey");
        else $(`#provider-${provider.provider}`).addClass("grey");
      });
    }

    // Add timeslots:
    const timeSlots = new Set(slots);
    let i = 0;
    timeSlots.forEach(slot => {
      if ((slot[1] === ":") || (Number(slot[1] < 2))) {
        $("#divBefore12Holder").append(`
          <div id="before12-${i}" class="slot">
            ${slot}am
          </div>
        `);
        $(`#before12-${i}`).on("click", function(event) {
          event.preventDefault();
          CurrentForm.SelectTime = true;
          CurrentForm.SelectedTime = slot;
          $(".slot").removeClass("selected");
          if (!$(this).hasClass("selected")) {
            $(this).addClass("selected");
            CurrentForm.spanDateTime.FHTML.SetTextStr(`<p>${CurrentForm.SelectedDate} [ ${slot} am ]</p>`);
            providers.forEach(provider => {
              $(`#providers>#provider-${provider.provider}`).removeClass("grey");
              if (provider.times.length === 0 || !provider.times.includes(slot)) {
                $(`#providers>#provider-${provider.provider}`).addClass("grey");
              }
              if (provider.times.includes(slot)) {
                if (CurrentForm.SelectedProvider === "none") CurrentForm.SelectedProvider = provider.provider;
              }
            });
          } else {
            $(this).removeClass("selected");
          }
          if ((CurrentForm.SelectDay) && (CurrentForm.SelectTime)) {
            CurrentForm.btnConfirm.SetEnabled(true);
            $("#btnConfirm").removeClass("disabled");
            CurrentForm.btnNext.SetEnabled(true);
            $("#btnNext").removeClass("disabled");
            $("#btnNext").html("Select Time");
          } else {
            CurrentForm.btnConfirm.SetEnabled(false);
            $("#btnConfirm").addClass("disabled");
            CurrentForm.btnNext.SetEnabled(false);
            $("#btnNext").addClass("disabled");
          }
        });
      } else if ((slot[2] === ":") && (Number(slot[1]) > 1) && (Number(slot[1]) < 7)) {
        $("#div12to5Holder").append(`
          <div id="12to5-${i}" data-provider="${providers.find(provider => provider.time === slot)}" class="slot">
            ${(Number(slot[0] + slot[1]) > 12 ? String(Number(slot[0] + slot[1]) - 12) + slot[2] + slot[3] + slot[4] : slot)}pm
          </div>
        `);
        $(`#12to5-${i}`).on("click", function(event) {
          event.preventDefault();
          CurrentForm.SelectTime = true;
          CurrentForm.SelectedTime = slot;
          $(".slot").removeClass("selected");
          if (!$(this).hasClass("selected")) {
            $(this).addClass("selected");
            CurrentForm.spanDateTime.FHTML.SetTextStr(`<p>${CurrentForm.SelectedDate} [ ${(Number(slot[0] + slot[1]) > 12 ? String(Number(slot[0] + slot[1]) - 12) + slot[2] + slot[3] + slot[4] : slot)} pm ]</p>`);
            providers.forEach(provider => {
              $(`#providers>#provider-${provider.provider}`).removeClass("grey");
              if (provider.times.length === 0 || !provider.times.includes(slot)) {
                $(`#providers>#provider-${provider.provider}`).addClass("grey");
              }
              if (provider.times.includes(slot)) {
                if (CurrentForm.SelectedProvider === "none") CurrentForm.SelectedProvider = provider.provider;
              }
            });
          } else {
            $(this).removeClass("selected");
          }
          if ((CurrentForm.SelectDay) && (CurrentForm.SelectTime)) {
            CurrentForm.btnConfirm.SetEnabled(true);
            $("#btnConfirm").removeClass("disabled");
            CurrentForm.btnNext.SetEnabled(true);
            $("#btnNext").removeClass("disabled");
            $("#btnNext").html("Select Time");
          } else {
            CurrentForm.btnConfirm.SetEnabled(false);
            $("#btnConfirm").addClass("disabled");
            CurrentForm.btnNext.SetEnabled(false);
            $("#btnNext").addClass("disabled");
          }
        });
      } else if ((slot[2] === ":") && (Number(slot[1]) > 5) && (Number(slot[1]) <= 9)) {
        $("#divAfter5Holder").append(`
          <div id="after5-${i}" data-provider="${providers.find(provider => provider.time === slot)}" class="slot">
            ${(String(Number(slot[0] + slot[1]) - 12) + slot[2] + slot[3] + slot[4])}pm
          </div>
        `);
        $(`#after5-${i}`).on("click", function(event) {
          event.preventDefault();
          CurrentForm.SelectTime = true;
          CurrentForm.SelectedTime = slot;
          $(".slot").removeClass("selected");
          if (!$(this).hasClass("selected")) {
            $(this).addClass("selected");
            CurrentForm.spanDateTime.FHTML.SetTextStr(`<p>${CurrentForm.SelectedDate} [ ${(String(Number(slot[0] + slot[1]) - 12) + slot[2] + slot[3] + slot[4])} pm ]</p>`);
            providers.forEach(provider => {
              $(`#providers>#provider-${provider.provider}`).removeClass("grey");
              if (provider.times.length === 0 || !provider.times.includes(slot)) {
                $(`#providers>#provider-${provider.provider}`).addClass("grey");
              }
              if (provider.times.includes(slot)) {
                if (CurrentForm.SelectedProvider === "none") CurrentForm.SelectedProvider = provider.provider;
              }
            });
          } else {
            $(this).removeClass("selected");
          }
          if ((CurrentForm.SelectDay) && (CurrentForm.SelectTime)) {
            CurrentForm.btnConfirm.SetEnabled(true);
            $("#btnConfirm").removeClass("disabled");
            CurrentForm.btnNext.SetEnabled(true);
            $("#btnNext").removeClass("disabled");
            $("#btnNext").html("Select Time");
          } else {
            CurrentForm.btnConfirm.SetEnabled(false);
            $("#btnConfirm").addClass("disabled");
            CurrentForm.btnNext.SetEnabled(false);
            $("#btnNext").addClass("disabled");
          }
        });
      }
      i++;
    });
    $("td").each(function() {
      CurrentForm.EmptyDays.forEach(day => {
        if ($(this).text() === day) $(this).addClass("jsCalendar-unselectable");
      });
    });
    setTimeout(() => {
      $("#divLoading").removeClass("show");
    }, 250); 
    this.Calendar.unfreeze();
  end;
  {$ENDIF}
end;

// Fill out text across the page, create the calendar with available dates, and display available time slots:
procedure TFormAppointment.WebFormCreate(Sender: TObject);
var
  CurrentForm: TFormAppointment;
begin
  CurrentForm := Self;
  SelectedTreatment := FormMain.SelectTreatment;
  ConnectionString := FormMain.ConnectionString;
  GlobalServer := FormMain.GlobalServer;
  {$IFNDEF WIN32}
  asm
    // Start the loading animation:
    $("#divLoading").addClass("show");

    // Scroll to the top of the form:
    $("html").scrollTop(0);
    $("body").scrollTop(0);

    // Set the duration of the appointment:
    this.spanDuration.FHTML.SetTextStr(`<p>${Number(this.SelectedTreatment.unit) * Number(this.SelectedTreatment.interval)} Mins</p>`);

    // Display the office name and the treatment type in the header:
    this.spanOffice.FHTML.SetTextStr(`<h6>${this.SelectedTreatment.office}</h6>`);
    this.spanTreatment.FHTML.SetTextStr(`<h3>${this.SelectedTreatment.name}</h3>`);

    this.ProviderCodes = [];

    // Add the Providers for that office to the list:
    $("#providers").append(`
      <option id="provider-placeholder" value="none">
        All Providers
      </option>
    `);
    this.SelectedTreatment.providers.forEach(provider => {
      $("#providers").append(`
        <option id="provider-${provider.Provider}" value="${provider.Provider}">
          ${provider.Name}  [${provider.Provider}]
        </option>
      `);
      this.ProviderCodes.push(provider.Provider);
    });

    // Create the Calendar component:
    const today = new Date();

    // Set the minimum date to today:
    let min = new Date().toISOString().slice(0, 10).split("-");
    min[0] = min.splice(2, 1, min[0])[0];
    min = min.join("/");

    // Set the max date if needed to 30 days from today:
    let max = new Date(today.setDate(today.getDate() + 30)).toISOString().slice(0, 10).split("-");
    max[0] = max.splice(2, 1, max[0])[0];
    max = max.join("/");

    // Assign the JSCalendar Object:
    this.Calendar = jsCalendar.new({
      target: "#divCalendar",
      date: pas.UnitMain.FormMain.FormattedDay,
      navigator: false,
      monthFormat: "month YYYY",
      dayFormat: "DDD",
      min,
      ... (this.GlobalServer) && { max }
    });

    // Function to see if a date object instance is valid:
    const isValidDate = d => d instanceof Date && !isNaN(d);

    // Function to format date to univeral use:
    const formatDate = day => {
      let date = new Date(day).toISOString().slice(0, 10).split("-");
      date[0] = date.splice(2, 1, date[0])[0];
      date[0] = date.splice(1, 1, date[0])[0];
      return date;
    }

    // Grab the first date of every month, or the minimum date if it's the current month:
    const getMonthStartDate = day => {
      const date = formatDate(day);
      date[1] = "01";
      return date.join("/");
    }

    // Grab the last day of the currently selected month:
    const getMonthEndDate = day => {
      const date = formatDate(day);
      for (let i = 31; i > 27; i--) {
        date[1] = `${i}`;
        if (isValidDate(new Date(date.join("/")))) {
          if (this.Calendar.isInMonth(new Date(date.join("/")))) {
            return date.join("/");
          }
        }
      }
    };

    // Set the target start date as the first date of the current month, or the minimum date if it's the current month:
    if (new Date(pas.UnitMain.FormMain.SelectedDay).getMonth() === new Date().getMonth()) this.StartDate = this.Calendar._options.min.toISOString().slice(0, 10);
    else this.StartDate = new Date(getMonthStartDate(new Date(pas.UnitMain.FormMain.SelectedDay))).toISOString().slice(0, 10);

    // Set the target end date as the last day of the current month:
    this.EndDate = new Date(getMonthEndDate(new Date(pas.UnitMain.FormMain.SelectedDay))).toISOString().slice(0, 10);

    this.InitialLoad = true;

    // Grab timeslots for today's date:
    this.RefreshDate = new Date(formatDate(pas.UnitMain.FormMain.SelectedDay).join("/")).toISOString().slice(0, 10);
    this.SelectedDate = new Date(pas.UnitMain.FormMain.SelectedDay);
    this.SelectedDate.setTime(this.SelectedDate.getTime() + this.SelectedDate.getTimezoneOffset() * 60 * 1000);
    this.SelectedDate = this.SelectedDate.toString().split(" ").slice(0, 4).join(" ");
    this.GetTimeSlots(this.StartDate, this.EndDate, this.ProviderCodes);

    this.spanDateTime.FHTML.SetTextStr(`<p>${this.SelectedDate}</p>`);

    // Assign Currently Selected Provider and on change function:
    this.SelectedProvider = $("#providers").val();

    // Create the "onDateClick" function:
    this.Calendar.onDateClick((event, date) => {
      if ((!this.Calendar.isFrozen()) && (event.target.className !== "jsCalendar-unselectable") && (event.target.className !== "jsCalendar-current") && (this.Calendar.isInMonth(date))) {
        $(".slot").removeClass("selected");
        this.Calendar.set(date);
        this.RefreshDate = new Date(date).toISOString().slice(0, 10);
        this.SelectedDate = new Date(date).toString().split(" ").slice(0, 4).join(" ");
        this.spanDateTime.FHTML.SetTextStr(`<p>${this.SelectedDate}</p>`);
        this.SelectedProvider = $("#providers").val();
        $("#divBefore12Holder").empty();
        $("#div12to5Holder").empty();
        $("#divAfter5Holder").empty();
        this.Calendar.freeze();
        if (this.SelectedProvider === "none") this.ParseTimeSlots(this.RefreshDate, this.ProviderCodes);
        else this.ParseTimeSlots(this.RefreshDate, [this.SelectedProvider]);
        this.SelectTime = false;
        this.btnConfirm.SetEnabled(false);
        $("#btnConfirm").addClass("disabled");
        this.btnNext.SetEnabled(true);
        $("#btnNext").removeClass("disabled");
      }
    });

    // Create the "changeMonth" function:
    const changeMonth = date => {
      // Reset some styling and disable "Select Date" button and freeze the calendar interactions until slots are loaded:
      $(".icon").prop("disabled", true);
      this.Calendar.freeze();
      $(".slot").removeClass("selected");
      this.SelectTime = false;
      this.btnConfirm.SetEnabled(false);
      $("#btnConfirm").addClass("disabled");
      this.btnNext.SetEnabled(false);
      $("#btnNext").addClass("disabled");
      $("#divBefore12Holder").empty();
      $("#div12to5Holder").empty();
      $("#divAfter5Holder").empty();

      // Render from the beginning of the selected month, unless it's the current month. If it is the current month, render from the set minimum date:
      if (date.getMonth() !== new Date().getMonth()) {
        this.SelectedDate = date.toString().split(" ").slice(0, 4).join(" ");
        this.RefreshDate = date.toISOString().slice(0, 10);
        this.Calendar.set(date);
        this.btnNext.SetEnabled(true);
        $("#btnNext").removeClass("disabled");
      } else if ((date.getMonth() === new Date().getMonth()) && (date.getFullYear() === new Date().getFullYear())) {
        this.btnNext.SetEnabled(true);
        $("#btnNext").removeClass("disabled");
        this.SelectedDate = this.Calendar._options.min.toString().split(" ").slice(0, 4).join(" ");
        this.RefreshDate = this.Calendar._options.min.toISOString().slice(0, 10);
        this.Calendar.set(this.Calendar._options.min);
      }

      // Find the end date for the month:
      this.spanDateTime.FHTML.SetTextStr(`<p>${this.SelectedDate}</p>`);
      this.EndDate = new Date(getMonthEndDate(date));
      const endYear = this.EndDate.getFullYear();
      const endMonth = this.EndDate.getMonth();
      const endDate = this.EndDate.getDate();
      this.EndDate = `${endYear}-${(endMonth + 1 > 9 ? endMonth + 1 : "0" + String(endMonth + 1))}-${endDate}`;
      if (!this.SelectedTime) {
        if (this.SelectedProvider === "none") this.GetTimeSlots(this.StartDate, this.EndDate, this.ProviderCodes);
        else this.GetTimeSlots(this.StartDate, this.EndDate, [this.SelectedProvider]);
      }
//      this.GetTimeSlots(this.RefreshDate, this.EndDate, this.ProviderCodes);
      this.Calendar.unfreeze();
      this.Calendar.refresh();   
    };

    // Add the selected date to the bottom of the page and replace the arrow icons for switching between months:
    $(".jsCalendar-title-left").append(`
      <span class="icon material-symbols-outlined">
        arrow_back
      </span>
    `);
    $(".jsCalendar-title-right").append(`
      <span class="icon material-symbols-outlined">
        arrow_forward
      </span>
    `);
    $(".icon").prop("disabled", false);

    $(".jsCalendar-title-left>span").on("click", e => {
      e.preventDefault();
      if (!$(".icon").prop("disabled")) {
        $("#divLoading").addClass("show");
        setTimeout(() => {
          let prev = this.Calendar.previous()._date;
          prev = new Date(`${prev.getMonth() + 1}-01-${prev.getFullYear()}`);
          changeMonth(prev);
        }, 250);
      }
    });
    $(".jsCalendar-title-right>span").on("click", e => {
      e.preventDefault();
      if (!$(".icon").prop("disabled")) {
        $("#divLoading").addClass("show");
        setTimeout(() => {
          const next = this.Calendar.next()._date;
          changeMonth(next);
        }, 250);
      }
    });

    $("#providers").on("change", () => {
      // Reload timeslots for the selected provider:
      $("#divLoading").addClass("show");
      this.SelectedProvider = $("#providers").val();
      this.SelectedTime = "";
      $("#divBefore12Holder").empty();
      $("#div12to5Holder").empty();
      $("#divAfter5Holder").empty();
      if (!this.SelectedTime) {
        if (this.SelectedProvider === "none") this.GetTimeSlots(this.StartDate, this.EndDate, this.ProviderCodes);
        else this.GetTimeSlots(this.StartDate, this.EndDate, [this.SelectedProvider]);
      }
      this.btnConfirm.SetEnabled(false);
      $("#btnConfirm").addClass("disabled");
      this.spanDateTime.FHTML.SetTextStr(`<p>${this.SelectedDate}</p>`);
      this.SelectTime = false;
      this.Calendar.refresh();
    });

    // Refresh the Calendar to update info:
    this.Calendar.refresh();
    this.Calendar.freeze();

  end;
  {$ENDIF}
  SelectDay := True;
  SelectTime := False;
end;

procedure TFormAppointment.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divAppointment := THTMLDiv.Create('divAppointment');
  divBooking := THTMLDiv.Create('divBooking');
  divAppoint := THTMLDiv.Create('divAppoint');
  divCalendarHolder := THTMLDiv.Create('divCalendarHolder');
  spanCalendarTitle := THTMLSpan.Create('spanCalendarTitle');
  divCalendar := THTMLDiv.Create('divCalendar');
  divAppointHolder := THTMLDiv.Create('divAppointHolder');
  spanAppointTitle := THTMLSpan.Create('spanAppointTitle');
  divAppointInfo := THTMLDiv.Create('divAppointInfo');
  divBefore12 := THTMLDiv.Create('divBefore12');
  spanBefore12Title := THTMLSpan.Create('spanBefore12Title');
  divBefore12Holder := THTMLDiv.Create('divBefore12Holder');
  div12to5 := THTMLDiv.Create('div12to5');
  span12to5Title := THTMLSpan.Create('span12to5Title');
  div12to5Holder := THTMLDiv.Create('div12to5Holder');
  divAfter5 := THTMLDiv.Create('divAfter5');
  spanAfter5Title := THTMLSpan.Create('spanAfter5Title');
  divAfter5Holder := THTMLDiv.Create('divAfter5Holder');
  divSelectedTime := THTMLDiv.Create('divSelectedTime');
  divDateTime := THTMLDiv.Create('divDateTime');
  spanDateTimeHeader := THTMLSpan.Create('spanDateTimeHeader');
  spanDateTime := THTMLSpan.Create('spanDateTime');
  divDuration := THTMLDiv.Create('divDuration');
  spanDurationHeader := THTMLSpan.Create('spanDurationHeader');
  spanDuration := THTMLSpan.Create('spanDuration');
  divSmallConfirm := THTMLDiv.Create('divSmallConfirm');
  spanSmallBack := THTMLSpan.Create('spanSmallBack');
  btnNext := TButton.Create('btnNext');
  divLoading := THTMLDiv.Create('divLoading');
  spanLoader := THTMLSpan.Create('spanLoader');
  spanLoadText := THTMLSpan.Create('spanLoadText');
  divProvider := THTMLDiv.Create('divProvider');
  divSelectProvider := THTMLDiv.Create('divSelectProvider');
  btnConfirm := TButton.Create('btnConfirm');
  divTreatment := THTMLDiv.Create('divTreatment');
  spanOffice := THTMLSpan.Create('spanOffice');
  spanTreatment := THTMLSpan.Create(Self);
  spanBack := THTMLSpan.Create('spanBack');

  divAppointment.BeforeLoadDFMValues;
  divBooking.BeforeLoadDFMValues;
  divAppoint.BeforeLoadDFMValues;
  divCalendarHolder.BeforeLoadDFMValues;
  spanCalendarTitle.BeforeLoadDFMValues;
  divCalendar.BeforeLoadDFMValues;
  divAppointHolder.BeforeLoadDFMValues;
  spanAppointTitle.BeforeLoadDFMValues;
  divAppointInfo.BeforeLoadDFMValues;
  divBefore12.BeforeLoadDFMValues;
  spanBefore12Title.BeforeLoadDFMValues;
  divBefore12Holder.BeforeLoadDFMValues;
  div12to5.BeforeLoadDFMValues;
  span12to5Title.BeforeLoadDFMValues;
  div12to5Holder.BeforeLoadDFMValues;
  divAfter5.BeforeLoadDFMValues;
  spanAfter5Title.BeforeLoadDFMValues;
  divAfter5Holder.BeforeLoadDFMValues;
  divSelectedTime.BeforeLoadDFMValues;
  divDateTime.BeforeLoadDFMValues;
  spanDateTimeHeader.BeforeLoadDFMValues;
  spanDateTime.BeforeLoadDFMValues;
  divDuration.BeforeLoadDFMValues;
  spanDurationHeader.BeforeLoadDFMValues;
  spanDuration.BeforeLoadDFMValues;
  divSmallConfirm.BeforeLoadDFMValues;
  spanSmallBack.BeforeLoadDFMValues;
  btnNext.BeforeLoadDFMValues;
  divLoading.BeforeLoadDFMValues;
  spanLoader.BeforeLoadDFMValues;
  spanLoadText.BeforeLoadDFMValues;
  divProvider.BeforeLoadDFMValues;
  divSelectProvider.BeforeLoadDFMValues;
  btnConfirm.BeforeLoadDFMValues;
  divTreatment.BeforeLoadDFMValues;
  spanOffice.BeforeLoadDFMValues;
  spanTreatment.BeforeLoadDFMValues;
  spanBack.BeforeLoadDFMValues;
  try
    Name := 'FormAppointment';
    Width := 1172;
    Height := 706;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divAppointment.SetParentComponent(Self);
    divAppointment.Name := 'divAppointment';
    divAppointment.Left := 8;
    divAppointment.Top := 8;
    divAppointment.Width := 1156;
    divAppointment.Height := 690;
    divAppointment.ElementClassName := 'appointment';
    divAppointment.HeightStyle := ssAuto;
    divAppointment.WidthStyle := ssAuto;
    divAppointment.ElementPosition := epIgnore;
    divAppointment.ElementFont := efCSS;
    divAppointment.Role := '';
    divBooking.SetParentComponent(divAppointment);
    divBooking.Name := 'divBooking';
    divBooking.Left := 16;
    divBooking.Top := 16;
    divBooking.Width := 1129;
    divBooking.Height := 657;
    divBooking.ElementClassName := 'booking';
    divBooking.HeightStyle := ssAuto;
    divBooking.WidthStyle := ssAuto;
    divBooking.ElementPosition := epIgnore;
    divBooking.ElementFont := efCSS;
    divBooking.Role := '';
    divAppoint.SetParentComponent(divBooking);
    divAppoint.Name := 'divAppoint';
    divAppoint.Left := 0;
    divAppoint.Top := 112;
    divAppoint.Width := 1129;
    divAppoint.Height := 545;
    divAppoint.ElementClassName := 'appointment-container';
    divAppoint.HeightStyle := ssAuto;
    divAppoint.WidthStyle := ssAuto;
    divAppoint.ChildOrder := 1;
    divAppoint.ElementPosition := epIgnore;
    divAppoint.ElementFont := efCSS;
    divAppoint.Role := '';
    divCalendarHolder.SetParentComponent(divAppoint);
    divCalendarHolder.Name := 'divCalendarHolder';
    divCalendarHolder.Left := 0;
    divCalendarHolder.Top := 0;
    divCalendarHolder.Width := 729;
    divCalendarHolder.Height := 545;
    divCalendarHolder.ElementClassName := 'calendar-holder';
    divCalendarHolder.HeightStyle := ssAuto;
    divCalendarHolder.WidthStyle := ssAuto;
    divCalendarHolder.ElementPosition := epIgnore;
    divCalendarHolder.ElementFont := efCSS;
    divCalendarHolder.Role := '';
    spanCalendarTitle.SetParentComponent(divCalendarHolder);
    spanCalendarTitle.Name := 'spanCalendarTitle';
    spanCalendarTitle.Left := 0;
    spanCalendarTitle.Top := 0;
    spanCalendarTitle.Width := 729;
    spanCalendarTitle.Height := 57;
    spanCalendarTitle.HeightStyle := ssAuto;
    spanCalendarTitle.WidthStyle := ssAuto;
    spanCalendarTitle.ElementPosition := epIgnore;
    spanCalendarTitle.ElementFont := efCSS;
    spanCalendarTitle.HTML.BeginUpdate;
    try
      spanCalendarTitle.HTML.Clear;
      spanCalendarTitle.HTML.Add('<h3 class="title">Select a Date for your Appointment</h3>');
    finally
      spanCalendarTitle.HTML.EndUpdate;
    end;
    spanCalendarTitle.Role := '';
    divCalendar.SetParentComponent(divCalendarHolder);
    divCalendar.Name := 'divCalendar';
    divCalendar.Left := 0;
    divCalendar.Top := 56;
    divCalendar.Width := 729;
    divCalendar.Height := 489;
    divCalendar.ElementClassName := 'calendar';
    divCalendar.HeightStyle := ssAuto;
    divCalendar.WidthStyle := ssAuto;
    divCalendar.ChildOrder := 1;
    divCalendar.ElementPosition := epIgnore;
    divCalendar.ElementFont := efCSS;
    divCalendar.Role := '';
    divAppointHolder.SetParentComponent(divAppoint);
    divAppointHolder.Name := 'divAppointHolder';
    divAppointHolder.Left := 728;
    divAppointHolder.Top := 0;
    divAppointHolder.Width := 401;
    divAppointHolder.Height := 545;
    divAppointHolder.ElementClassName := 'info-holder';
    divAppointHolder.HeightStyle := ssAuto;
    divAppointHolder.WidthStyle := ssAuto;
    divAppointHolder.ChildOrder := 1;
    divAppointHolder.ElementPosition := epIgnore;
    divAppointHolder.ElementFont := efCSS;
    divAppointHolder.Role := '';
    spanAppointTitle.SetParentComponent(divAppointHolder);
    spanAppointTitle.Name := 'spanAppointTitle';
    spanAppointTitle.Left := 0;
    spanAppointTitle.Top := 0;
    spanAppointTitle.Width := 401;
    spanAppointTitle.Height := 57;
    spanAppointTitle.HeightStyle := ssAuto;
    spanAppointTitle.WidthStyle := ssAuto;
    spanAppointTitle.ElementPosition := epIgnore;
    spanAppointTitle.ElementFont := efCSS;
    spanAppointTitle.HTML.BeginUpdate;
    try
      spanAppointTitle.HTML.Clear;
      spanAppointTitle.HTML.Add('<h3 class="title">Select a Time Slot for your Appointment</h3>');
    finally
      spanAppointTitle.HTML.EndUpdate;
    end;
    spanAppointTitle.Role := '';
    divAppointInfo.SetParentComponent(divAppointHolder);
    divAppointInfo.Name := 'divAppointInfo';
    divAppointInfo.Left := 0;
    divAppointInfo.Top := 56;
    divAppointInfo.Width := 401;
    divAppointInfo.Height := 393;
    divAppointInfo.ElementClassName := 'info';
    divAppointInfo.HeightStyle := ssAuto;
    divAppointInfo.WidthStyle := ssAuto;
    divAppointInfo.ChildOrder := 1;
    divAppointInfo.ElementPosition := epIgnore;
    divAppointInfo.ElementFont := efCSS;
    divAppointInfo.Role := '';
    divBefore12.SetParentComponent(divAppointInfo);
    divBefore12.Name := 'divBefore12';
    divBefore12.Left := 0;
    divBefore12.Top := 0;
    divBefore12.Width := 129;
    divBefore12.Height := 393;
    divBefore12.ElementClassName := 'slots';
    divBefore12.HeightStyle := ssAuto;
    divBefore12.WidthStyle := ssAuto;
    divBefore12.ElementPosition := epIgnore;
    divBefore12.ElementFont := efCSS;
    divBefore12.Role := '';
    spanBefore12Title.SetParentComponent(divBefore12);
    spanBefore12Title.Name := 'spanBefore12Title';
    spanBefore12Title.Left := 0;
    spanBefore12Title.Top := 0;
    spanBefore12Title.Width := 129;
    spanBefore12Title.Height := 41;
    spanBefore12Title.ElementClassName := 'slot-title';
    spanBefore12Title.HeightStyle := ssAuto;
    spanBefore12Title.WidthStyle := ssAuto;
    spanBefore12Title.ElementPosition := epIgnore;
    spanBefore12Title.ElementFont := efCSS;
    spanBefore12Title.HTML.BeginUpdate;
    try
      spanBefore12Title.HTML.Clear;
      spanBefore12Title.HTML.Add('<h4>Before 12pm</h4>');
    finally
      spanBefore12Title.HTML.EndUpdate;
    end;
    spanBefore12Title.Role := '';
    divBefore12Holder.SetParentComponent(divBefore12);
    divBefore12Holder.Name := 'divBefore12Holder';
    divBefore12Holder.Left := 0;
    divBefore12Holder.Top := 40;
    divBefore12Holder.Width := 129;
    divBefore12Holder.Height := 352;
    divBefore12Holder.ElementClassName := 'slot-holder';
    divBefore12Holder.HeightStyle := ssAuto;
    divBefore12Holder.WidthStyle := ssAuto;
    divBefore12Holder.ChildOrder := 1;
    divBefore12Holder.ElementPosition := epIgnore;
    divBefore12Holder.ElementFont := efCSS;
    divBefore12Holder.Role := '';
    div12to5.SetParentComponent(divAppointInfo);
    div12to5.Name := 'div12to5';
    div12to5.Left := 127;
    div12to5.Top := 0;
    div12to5.Width := 147;
    div12to5.Height := 393;
    div12to5.ElementClassName := 'slots';
    div12to5.HeightStyle := ssAuto;
    div12to5.WidthStyle := ssAuto;
    div12to5.ChildOrder := 1;
    div12to5.ElementPosition := epIgnore;
    div12to5.ElementFont := efCSS;
    div12to5.Role := '';
    span12to5Title.SetParentComponent(div12to5);
    span12to5Title.Name := 'span12to5Title';
    span12to5Title.Left := 0;
    span12to5Title.Top := 0;
    span12to5Title.Width := 147;
    span12to5Title.Height := 41;
    span12to5Title.ElementClassName := 'slot-title';
    span12to5Title.HeightStyle := ssAuto;
    span12to5Title.WidthStyle := ssAuto;
    span12to5Title.ElementPosition := epIgnore;
    span12to5Title.ElementFont := efCSS;
    span12to5Title.HTML.BeginUpdate;
    try
      span12to5Title.HTML.Clear;
      span12to5Title.HTML.Add('<h4>12pm to 5pm</h4>');
    finally
      span12to5Title.HTML.EndUpdate;
    end;
    span12to5Title.Role := '';
    div12to5Holder.SetParentComponent(div12to5);
    div12to5Holder.Name := 'div12to5Holder';
    div12to5Holder.Left := 0;
    div12to5Holder.Top := 40;
    div12to5Holder.Width := 147;
    div12to5Holder.Height := 352;
    div12to5Holder.ElementClassName := 'slot-holder';
    div12to5Holder.HeightStyle := ssAuto;
    div12to5Holder.WidthStyle := ssAuto;
    div12to5Holder.ChildOrder := 1;
    div12to5Holder.ElementPosition := epIgnore;
    div12to5Holder.ElementFont := efCSS;
    div12to5Holder.Role := '';
    divAfter5.SetParentComponent(divAppointInfo);
    divAfter5.Name := 'divAfter5';
    divAfter5.Left := 272;
    divAfter5.Top := 0;
    divAfter5.Width := 129;
    divAfter5.Height := 393;
    divAfter5.ElementClassName := 'slots';
    divAfter5.HeightStyle := ssAuto;
    divAfter5.WidthStyle := ssAuto;
    divAfter5.ChildOrder := 2;
    divAfter5.ElementPosition := epIgnore;
    divAfter5.ElementFont := efCSS;
    divAfter5.Role := '';
    spanAfter5Title.SetParentComponent(divAfter5);
    spanAfter5Title.Name := 'spanAfter5Title';
    spanAfter5Title.Left := 0;
    spanAfter5Title.Top := 0;
    spanAfter5Title.Width := 129;
    spanAfter5Title.Height := 41;
    spanAfter5Title.ElementClassName := 'slot-title';
    spanAfter5Title.HeightStyle := ssAuto;
    spanAfter5Title.WidthStyle := ssAuto;
    spanAfter5Title.ElementPosition := epIgnore;
    spanAfter5Title.ElementFont := efCSS;
    spanAfter5Title.HTML.BeginUpdate;
    try
      spanAfter5Title.HTML.Clear;
      spanAfter5Title.HTML.Add('<h4>After 5pm</h4>');
    finally
      spanAfter5Title.HTML.EndUpdate;
    end;
    spanAfter5Title.Role := '';
    divAfter5Holder.SetParentComponent(divAfter5);
    divAfter5Holder.Name := 'divAfter5Holder';
    divAfter5Holder.Left := 0;
    divAfter5Holder.Top := 40;
    divAfter5Holder.Width := 129;
    divAfter5Holder.Height := 352;
    divAfter5Holder.ElementClassName := 'slot-holder';
    divAfter5Holder.HeightStyle := ssAuto;
    divAfter5Holder.WidthStyle := ssAuto;
    divAfter5Holder.ChildOrder := 1;
    divAfter5Holder.ElementPosition := epIgnore;
    divAfter5Holder.ElementFont := efCSS;
    divAfter5Holder.Role := '';
    divSelectedTime.SetParentComponent(divAppointHolder);
    divSelectedTime.Name := 'divSelectedTime';
    divSelectedTime.Left := 0;
    divSelectedTime.Top := 448;
    divSelectedTime.Width := 401;
    divSelectedTime.Height := 97;
    divSelectedTime.ElementClassName := 'date-time';
    divSelectedTime.HeightStyle := ssAuto;
    divSelectedTime.WidthStyle := ssAuto;
    divSelectedTime.ChildOrder := 2;
    divSelectedTime.ElementPosition := epIgnore;
    divSelectedTime.ElementFont := efCSS;
    divSelectedTime.Role := '';
    divDateTime.SetParentComponent(divSelectedTime);
    divDateTime.Name := 'divDateTime';
    divDateTime.Left := 3;
    divDateTime.Top := 3;
    divDateTime.Width := 190;
    divDateTime.Height := 92;
    divDateTime.ElementClassName := 'holder';
    divDateTime.HeightStyle := ssAuto;
    divDateTime.WidthStyle := ssAuto;
    divDateTime.ElementPosition := epIgnore;
    divDateTime.ElementFont := efCSS;
    divDateTime.Role := '';
    spanDateTimeHeader.SetParentComponent(divDateTime);
    spanDateTimeHeader.Name := 'spanDateTimeHeader';
    spanDateTimeHeader.Left := 3;
    spanDateTimeHeader.Top := 3;
    spanDateTimeHeader.Width := 184;
    spanDateTimeHeader.Height := 41;
    spanDateTimeHeader.ElementClassName := 'header';
    spanDateTimeHeader.HeightStyle := ssAuto;
    spanDateTimeHeader.WidthStyle := ssAuto;
    spanDateTimeHeader.ElementPosition := epIgnore;
    spanDateTimeHeader.ElementFont := efCSS;
    spanDateTimeHeader.HTML.BeginUpdate;
    try
      spanDateTimeHeader.HTML.Clear;
      spanDateTimeHeader.HTML.Add('<span class="material-symbols-outlined">calendar_today </span><p>Appointment Time</p>');
    finally
      spanDateTimeHeader.HTML.EndUpdate;
    end;
    spanDateTimeHeader.Role := '';
    spanDateTime.SetParentComponent(divDateTime);
    spanDateTime.Name := 'spanDateTime';
    spanDateTime.Left := 3;
    spanDateTime.Top := 48;
    spanDateTime.Width := 184;
    spanDateTime.Height := 41;
    spanDateTime.ElementClassName := 'content';
    spanDateTime.HeightStyle := ssAuto;
    spanDateTime.WidthStyle := ssAuto;
    spanDateTime.ChildOrder := 1;
    spanDateTime.ElementPosition := epIgnore;
    spanDateTime.ElementFont := efCSS;
    spanDateTime.Role := '';
    divDuration.SetParentComponent(divSelectedTime);
    divDuration.Name := 'divDuration';
    divDuration.Left := 207;
    divDuration.Top := 3;
    divDuration.Width := 191;
    divDuration.Height := 92;
    divDuration.ElementClassName := 'holder';
    divDuration.HeightStyle := ssAuto;
    divDuration.WidthStyle := ssAuto;
    divDuration.ChildOrder := 1;
    divDuration.ElementPosition := epIgnore;
    divDuration.ElementFont := efCSS;
    divDuration.Role := '';
    spanDurationHeader.SetParentComponent(divDuration);
    spanDurationHeader.Name := 'spanDurationHeader';
    spanDurationHeader.Left := 3;
    spanDurationHeader.Top := 3;
    spanDurationHeader.Width := 185;
    spanDurationHeader.Height := 41;
    spanDurationHeader.ElementClassName := 'header';
    spanDurationHeader.HeightStyle := ssAuto;
    spanDurationHeader.WidthStyle := ssAuto;
    spanDurationHeader.ElementPosition := epIgnore;
    spanDurationHeader.ElementFont := efCSS;
    spanDurationHeader.HTML.BeginUpdate;
    try
      spanDurationHeader.HTML.Clear;
      spanDurationHeader.HTML.Add('<span class="material-symbols-outlined">hourglass </span><p>Duration</p>');
    finally
      spanDurationHeader.HTML.EndUpdate;
    end;
    spanDurationHeader.Role := '';
    spanDuration.SetParentComponent(divDuration);
    spanDuration.Name := 'spanDuration';
    spanDuration.Left := 3;
    spanDuration.Top := 48;
    spanDuration.Width := 185;
    spanDuration.Height := 41;
    spanDuration.ElementClassName := 'content';
    spanDuration.HeightStyle := ssAuto;
    spanDuration.WidthStyle := ssAuto;
    spanDuration.ChildOrder := 1;
    spanDuration.ElementPosition := epIgnore;
    spanDuration.ElementFont := efCSS;
    spanDuration.Role := '';
    divSmallConfirm.SetParentComponent(divAppoint);
    divSmallConfirm.Name := 'divSmallConfirm';
    divSmallConfirm.Left := 222;
    divSmallConfirm.Top := 432;
    divSmallConfirm.Width := 299;
    divSmallConfirm.Height := 73;
    divSmallConfirm.ElementClassName := 'small-confirm';
    divSmallConfirm.HeightStyle := ssAuto;
    divSmallConfirm.WidthStyle := ssAuto;
    divSmallConfirm.ChildOrder := 2;
    divSmallConfirm.ElementPosition := epIgnore;
    divSmallConfirm.ElementFont := efCSS;
    divSmallConfirm.Role := '';
    spanSmallBack.SetParentComponent(divSmallConfirm);
    spanSmallBack.Name := 'spanSmallBack';
    spanSmallBack.Left := 16;
    spanSmallBack.Top := 16;
    spanSmallBack.Width := 100;
    spanSmallBack.Height := 41;
    spanSmallBack.ElementClassName := 'back';
    spanSmallBack.HeightStyle := ssAuto;
    spanSmallBack.WidthStyle := ssAuto;
    spanSmallBack.ChildOrder := 1;
    spanSmallBack.ElementPosition := epIgnore;
    spanSmallBack.ElementFont := efCSS;
    spanSmallBack.HTML.BeginUpdate;
    try
      spanSmallBack.HTML.Clear;
      spanSmallBack.HTML.Add('<h6>Back</h6>');
    finally
      spanSmallBack.HTML.EndUpdate;
    end;
    spanSmallBack.Role := '';
    SetEvent(spanSmallBack, Self, 'OnClick', 'spanSmallBackClick');
    btnNext.SetParentComponent(divSmallConfirm);
    btnNext.Name := 'btnNext';
    btnNext.Left := 182;
    btnNext.Top := 24;
    btnNext.Width := 96;
    btnNext.Height := 25;
    btnNext.Caption := 'Select Date';
    btnNext.ChildOrder := 1;
    btnNext.ElementClassName := 'btn-confirm';
    btnNext.ElementFont := efCSS;
    btnNext.ElementPosition := epIgnore;
    btnNext.HeightStyle := ssAuto;
    btnNext.HeightPercent := 100.000000000000000000;
    btnNext.WidthStyle := ssAuto;
    btnNext.WidthPercent := 100.000000000000000000;
    SetEvent(btnNext, Self, 'OnClick', 'btnNextClick');
    divLoading.SetParentComponent(divAppoint);
    divLoading.Name := 'divLoading';
    divLoading.Left := 320;
    divLoading.Top := 160;
    divLoading.Width := 100;
    divLoading.Height := 81;
    divLoading.ElementClassName := 'loader-container';
    divLoading.HeightStyle := ssAuto;
    divLoading.WidthStyle := ssAuto;
    divLoading.ChildOrder := 1;
    divLoading.ElementPosition := epIgnore;
    divLoading.ElementFont := efCSS;
    divLoading.Role := '';
    spanLoader.SetParentComponent(divLoading);
    spanLoader.Name := 'spanLoader';
    spanLoader.Left := 0;
    spanLoader.Top := 0;
    spanLoader.Width := 100;
    spanLoader.Height := 41;
    spanLoader.ElementClassName := 'loader';
    spanLoader.HeightStyle := ssAuto;
    spanLoader.WidthStyle := ssAuto;
    spanLoader.ElementPosition := epIgnore;
    spanLoader.ElementFont := efCSS;
    spanLoader.Role := '';
    spanLoadText.SetParentComponent(divLoading);
    spanLoadText.Name := 'spanLoadText';
    spanLoadText.Left := 0;
    spanLoadText.Top := 40;
    spanLoadText.Width := 100;
    spanLoadText.Height := 41;
    spanLoadText.ElementClassName := 'loading-text';
    spanLoadText.HeightStyle := ssAuto;
    spanLoadText.WidthStyle := ssAuto;
    spanLoadText.ChildOrder := 1;
    spanLoadText.ElementPosition := epIgnore;
    spanLoadText.ElementFont := efCSS;
    spanLoadText.HTML.BeginUpdate;
    try
      spanLoadText.HTML.Clear;
      spanLoadText.HTML.Add('<h3 id="load-text">Loading Time Slots</h3>');
    finally
      spanLoadText.HTML.EndUpdate;
    end;
    spanLoadText.Role := '';
    divProvider.SetParentComponent(divBooking);
    divProvider.Name := 'divProvider';
    divProvider.Left := 0;
    divProvider.Top := 0;
    divProvider.Width := 1129;
    divProvider.Height := 113;
    divProvider.ElementClassName := 'provider';
    divProvider.HeightStyle := ssAuto;
    divProvider.WidthStyle := ssAuto;
    divProvider.ElementPosition := epIgnore;
    divProvider.ElementFont := efCSS;
    divProvider.Role := '';
    divSelectProvider.SetParentComponent(divProvider);
    divSelectProvider.Name := 'divSelectProvider';
    divSelectProvider.Left := 659;
    divSelectProvider.Top := 3;
    divSelectProvider.Width := 286;
    divSelectProvider.Height := 107;
    divSelectProvider.ElementClassName := 'select-provider';
    divSelectProvider.HeightStyle := ssAuto;
    divSelectProvider.WidthStyle := ssAuto;
    divSelectProvider.ChildOrder := 1;
    divSelectProvider.ElementPosition := epIgnore;
    divSelectProvider.ElementFont := efCSS;
    divSelectProvider.HTML.BeginUpdate;
    try
      divSelectProvider.HTML.Clear;
      divSelectProvider.HTML.Add('<label for="providers"><h3>Select a Provider</h3></label>');
      divSelectProvider.HTML.Add('<select name="providers" id="providers" class="providers"></select>');
    finally
      divSelectProvider.HTML.EndUpdate;
    end;
    divSelectProvider.Role := '';
    btnConfirm.SetParentComponent(divProvider);
    btnConfirm.Name := 'btnConfirm';
    btnConfirm.Left := 992;
    btnConfirm.Top := 48;
    btnConfirm.Width := 96;
    btnConfirm.Height := 25;
    btnConfirm.Caption := 'Select Time';
    btnConfirm.ChildOrder := 3;
    btnConfirm.ElementClassName := 'btn-confirm disabled';
    btnConfirm.ElementFont := efCSS;
    btnConfirm.ElementPosition := epIgnore;
    btnConfirm.Enabled := False;
    btnConfirm.HeightStyle := ssAuto;
    btnConfirm.HeightPercent := 100.000000000000000000;
    btnConfirm.WidthStyle := ssAuto;
    btnConfirm.WidthPercent := 100.000000000000000000;
    SetEvent(btnConfirm, Self, 'OnClick', 'btnConfirmClick');
    divTreatment.SetParentComponent(divProvider);
    divTreatment.Name := 'divTreatment';
    divTreatment.Left := 219;
    divTreatment.Top := 3;
    divTreatment.Width := 434;
    divTreatment.Height := 107;
    divTreatment.ElementClassName := 'treatment-info';
    divTreatment.HeightStyle := ssAuto;
    divTreatment.WidthStyle := ssAuto;
    divTreatment.ElementPosition := epIgnore;
    divTreatment.ElementFont := efCSS;
    divTreatment.Role := '';
    spanOffice.SetParentComponent(divTreatment);
    spanOffice.Name := 'spanOffice';
    spanOffice.Left := 3;
    spanOffice.Top := 4;
    spanOffice.Width := 428;
    spanOffice.Height := 45;
    spanOffice.HeightStyle := ssAuto;
    spanOffice.WidthStyle := ssAuto;
    spanOffice.ElementPosition := epIgnore;
    spanOffice.ElementFont := efCSS;
    spanOffice.Role := '';
    spanTreatment.SetParentComponent(divTreatment);
    spanTreatment.Name := 'spanTreatment';
    spanTreatment.Left := 3;
    spanTreatment.Top := 55;
    spanTreatment.Width := 428;
    spanTreatment.Height := 49;
    spanTreatment.HeightStyle := ssAuto;
    spanTreatment.WidthStyle := ssAuto;
    spanTreatment.ChildOrder := 1;
    spanTreatment.ElementPosition := epIgnore;
    spanTreatment.ElementFont := efCSS;
    spanTreatment.Role := '';
    spanBack.SetParentComponent(divProvider);
    spanBack.Name := 'spanBack';
    spanBack.Left := 53;
    spanBack.Top := 32;
    spanBack.Width := 100;
    spanBack.Height := 41;
    spanBack.ElementClassName := 'back';
    spanBack.HeightStyle := ssAuto;
    spanBack.WidthStyle := ssAuto;
    spanBack.ChildOrder := 2;
    spanBack.ElementPosition := epIgnore;
    spanBack.ElementFont := efCSS;
    spanBack.HTML.BeginUpdate;
    try
      spanBack.HTML.Clear;
      spanBack.HTML.Add('<h6>Back</h6>');
    finally
      spanBack.HTML.EndUpdate;
    end;
    spanBack.Role := '';
    SetEvent(spanBack, Self, 'OnClick', 'spanBackClick');
  finally
    divAppointment.AfterLoadDFMValues;
    divBooking.AfterLoadDFMValues;
    divAppoint.AfterLoadDFMValues;
    divCalendarHolder.AfterLoadDFMValues;
    spanCalendarTitle.AfterLoadDFMValues;
    divCalendar.AfterLoadDFMValues;
    divAppointHolder.AfterLoadDFMValues;
    spanAppointTitle.AfterLoadDFMValues;
    divAppointInfo.AfterLoadDFMValues;
    divBefore12.AfterLoadDFMValues;
    spanBefore12Title.AfterLoadDFMValues;
    divBefore12Holder.AfterLoadDFMValues;
    div12to5.AfterLoadDFMValues;
    span12to5Title.AfterLoadDFMValues;
    div12to5Holder.AfterLoadDFMValues;
    divAfter5.AfterLoadDFMValues;
    spanAfter5Title.AfterLoadDFMValues;
    divAfter5Holder.AfterLoadDFMValues;
    divSelectedTime.AfterLoadDFMValues;
    divDateTime.AfterLoadDFMValues;
    spanDateTimeHeader.AfterLoadDFMValues;
    spanDateTime.AfterLoadDFMValues;
    divDuration.AfterLoadDFMValues;
    spanDurationHeader.AfterLoadDFMValues;
    spanDuration.AfterLoadDFMValues;
    divSmallConfirm.AfterLoadDFMValues;
    spanSmallBack.AfterLoadDFMValues;
    btnNext.AfterLoadDFMValues;
    divLoading.AfterLoadDFMValues;
    spanLoader.AfterLoadDFMValues;
    spanLoadText.AfterLoadDFMValues;
    divProvider.AfterLoadDFMValues;
    divSelectProvider.AfterLoadDFMValues;
    btnConfirm.AfterLoadDFMValues;
    divTreatment.AfterLoadDFMValues;
    spanOffice.AfterLoadDFMValues;
    spanTreatment.AfterLoadDFMValues;
    spanBack.AfterLoadDFMValues;
  end;
end;

end.