unit UnitPatient;

interface

uses

  System.SysUtils,
  System.Classes,
  System.Math,
  System.Types,
  System.DateUtils,

  JSDelphiSystem,
  JS,
  Web,

  XData.Web.Client,
  XData.Web.Connection,

  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls,
  WEBLib.WebTools,
  WEBLib.JSON,
  WEBLib.REST,

  Vcl.Controls,
  Vcl.StdCtrls,
  VCL.TMSFNCCustomControl,
  VCL.TMSFNCWebBrowser,
  VCL.TMSFNCCustomWEBControl,
  WEBLib.DropDown,
  WEBLib.ExtCtrls,
  WEBLib.ComCtrls,

  Types_Vars_API,
  Types_Vars_Security;

type

  TFormPatient = class(TForm)

    divPatient: THTMLDiv;
    divAppointInfo: THTMLDiv;
    divPatientInfo: THTMLDiv;
    btnConfirm: TButton;
    divDuration: THTMLDiv;
    divProvider: THTMLDiv;
    divOfficeInfo: THTMLDiv;
    divAppoint: THTMLDiv;
    divNewPatient: THTMLDiv;
    divPatientHolder: THTMLDiv;
    divLastName: THTMLDiv;
    divFirstName: THTMLDiv;
    divEmail: THTMLDiv;
    divPhone: THTMLDiv;
    divConfirm: THTMLDiv;
    spanBack: THTMLSpan;
    spanOffice: THTMLSpan;
    spanTreatment: THTMLSpan;
    spanDurationHeader: THTMLSpan;
    spanDuration: THTMLSpan;
    divDateTime: THTMLDiv;
    spanDateTimeHeader: THTMLSpan;
    spanDateTime: THTMLSpan;
    spanProvider: THTMLSpan;
    spanProviderHeader: THTMLSpan;

    procedure spanBackClick(Sender: TObject);
    procedure btnConfirmClick(Sender: TObject);
    [async] procedure ConfirmBooking(Data: JSValue); async;

    procedure WebFormCreate(Sender: TObject);

  private

    { Private declarations }

  public

    { Public declarations }

    ConnectionString: String;
    GlobalServer: Boolean;
    TreatmentInfo, AppointmentInfo, Day, Time, Provider, PatientInfo, Treatment: JSValue;

  protected procedure LoadDFMValues; override; end;

var
  FormPatient: TFormPatient;

implementation

{$R *.dfm}

uses
  UnitMain, UnitConfirm;

// Return to the appointments page:
procedure TFormPatient.spanBackClick(Sender: TObject);
begin
  FormMain.LoadAppointmentForm(FormMain.ConnectionString, FormMain.FailedConnectionString, FormMain.OfficeSequence, FormMain.ServerPort, GlobalServer, Treatment);
end;

// Submit Patient information along with the appointment information:
procedure TFormPatient.btnConfirmClick(Sender: TObject);
begin
  // Assign patient information:
  {$IFNDEF WIN32}
  asm
    const validateEmail = email => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };
    const validatePhone = phone => {
      return phone.match(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
      );
    }
    const required = [];
    $("#divPatientInfo>div").each(function() {
      if ($(this).children("input").val() === "") {
        required.push($(this).children("input"));
        if ($(this).children(".required-text").length > 0) $(this).children(".required-text").remove();
        $(this).append(`
          <p class="required-text">
            ${ $(this).children("label").text().split(" ")[2] } ${ $(this).children("label").text().split(" ")[3] } is Required.
          </p>
        `);
      }
      $(this).children("input").on("change", function(e) {
        $(this).removeClass("required-outline");
        $(this).parent().children(".required-text").remove();
      });
    });
    if (!validateEmail($("#email").val())) {
      required.push($("#email"));
      if ($("#divEmail").children(".required-text").length > 0) $("#divEmail").children(".required-text").remove();
      $("#divEmail").append(`
        <p class="required-text">
          A valid Email Address is Required.
        </p>
      `);
    }
    if (!validatePhone($("#phone").val())) {
      required.push($("#phone"));
      if ($("#divPhone").children(".required-text").length > 0) $("#divPhone").children(".required-text").remove();
      $("#divPhone").append(`
        <p class="required-text">
          A valid Phone Number is Required.
        </p>
      `);
    }
    if (required.length > 0) {
      required.forEach(field => {
        field.addClass("required-outline");
        window.scrollTo({
          top: 0,
          left: 0,
          behaviour: "smooth"
        });
      });
      return;
    }
    if (this.GlobalServer) {
      this.OfficeSequence = this.AppointmentInfo.OfficeSequence;
      this.ConnectionString = this.AppointmentInfo.FailedconnectionString;
    }
    const AppointDate = this.AppointmentInfo.day
    const splitTime = this.Time.split(":");
    const TimeSlot = Number(splitTime[0]) * 60 + Number(splitTime[1]);
    const Units = this.Treatment.unit;
    const Provider = this.Provider;
    const FirstName = $("#first-name").val();
    const LastName = $("#last-name").val();
    const Email = $("#email").val();
    const Phone = $("#phone").val();
    const IsNewPatient = $("#select-new-patient").val() === "yes" ? true : false;
    this.PatientInfo = {
      AppointDate,
      TimeSlot,
      Units,
      Provider,
      FirstName,
      LastName,
      Email,
      Phone,
      IsNewPatient,
    };
    if (this.GlobalServer) {
      const ConnectionString = this.ConnectionString;
      this.PatientInfo.ConnectionString = ConnectionString;
    }
    this.ConfirmBooking(this.PatientInfo);
  end;
  {$ENDIF}
end;

// Open the confirm popup:
procedure TFormPatient.ConfirmBooking(Data: JSValue);
var
  NewConfirm: TFormConfirm;
begin
  {$IFNDEF WIN32}
  asm
    $("html").scrollTop(0);
    $("body").scrollTop(0);
  end;
  {$ENDIF}

  NewConfirm := TFormConfirm.Create(Self);
  NewConfirm.Popup := True;
  NewConfirm.PopupOpacity := 0.7;
  NewConfirm.AppointmentInfo := Data;
  NewConfirm.TreatmentInfo := TreatmentInfo;

  {$IFNDEF WIN32}
  asm
    setTimeout(function() {
      $("html").css("overflow-x", "hidden");
      $("body").css("overflow-x", "hidden");
      $("html").css("overflow-y", "hidden");
      $("body").css("overflow-y", "hidden");
    }, 100);
  end;
  {$ENDIF}

  // Load the Form:
  await( TFormConfirm, NewConfirm.Load );

  // Wait for the form to be Closed, then act accordingly:
  try
    await( TModalResult, NewConfirm.Execute );
  finally
    {$IFNDEF WIN32}
    asm
      $("html").css("overflow-x", "auto");
      $("body").css("overflow-x", "auto");
      $("html").css("overflow-y", "auto");
      $("body").css("overflow-y", "auto");
    end;
    {$ENDIF}
    NewConfirm.Free;
  end;
end;

// Assign appointment info:
procedure TFormPatient.WebFormCreate(Sender: TObject);
begin
  AppointmentInfo := FormMain.Appointment;
  TreatmentInfo := FormMain.TreatmentID;
  GlobalServer := FormMain.GlobalServer;
  {$IFNDEF WIN32}
  asm
    // Assign Date Formats:
    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    this.Day = this.AppointmentInfo.day;
    let displayDay = new Date(this.Day);
    displayDay.setTime(displayDay.getTime() + displayDay.getTimezoneOffset() * 60 * 1000);
    displayDay = displayDay.toString().split(" ").slice(0, 4).join(" ");
    this.Time = this.AppointmentInfo.time;
    this.Treatment = this.AppointmentInfo.treatment;
    this.Provider = this.AppointmentInfo.provider;
    this.ConnectionString = this.AppointmentInfo.connectionString;

    // Assign AM/PM time to the time property:
    let time = this.AppointmentInfo.time;
    if (time[1] === ":") time = time + " am";
    else {
      if (Number(time[0] + time[1]) < 12) time = time + " am";
      else if (Number(time[0] + time[1]) === 12) time = time + " pm";
      else time = `${Number(time[0] + time[1]) - 12}${time[2]}${time[3]}${time[4]} pm`;
    }

    // Assign all the empty spans with office and appointment information:
    this.spanOffice.FHTML.SetTextStr(`<h6>${this.Treatment.office}</h6>`);
    this.spanTreatment.FHTML.SetTextStr(`<h3>${this.Treatment.name}</h3>`);
    this.spanDuration.FHTML.SetTextStr(`<p>${Number(this.Treatment.unit) * Number(this.Treatment.interval)} Mins</p>`);
    this.spanDateTime.FHTML.SetTextStr(`<p>${displayDay} [ ${time} ]</p>`);
    this.spanProvider.FHTML.SetTextStr(`<p>${this.Provider.Name} [ ${this.Provider.Provider} ]`);
    $("#phone").on("input", function(e) {
      let x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    });
  end;
  {$ENDIF}
end;

procedure TFormPatient.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divPatient := THTMLDiv.Create('divPatient');
  divPatientHolder := THTMLDiv.Create('divPatientHolder');
  divPatientInfo := THTMLDiv.Create('divPatientInfo');
  divNewPatient := THTMLDiv.Create('divNewPatient');
  divLastName := THTMLDiv.Create('divLastName');
  divFirstName := THTMLDiv.Create('divFirstName');
  divEmail := THTMLDiv.Create('divEmail');
  divPhone := THTMLDiv.Create('divPhone');
  divAppointInfo := THTMLDiv.Create('divAppointInfo');
  divOfficeInfo := THTMLDiv.Create('divOfficeInfo');
  spanOffice := THTMLSpan.Create('spanOffice');
  spanTreatment := THTMLSpan.Create('spanTreatment');
  divAppoint := THTMLDiv.Create('divAppoint');
  divDuration := THTMLDiv.Create('divDuration');
  spanDurationHeader := THTMLSpan.Create('spanDurationHeader');
  spanDuration := THTMLSpan.Create('spanDuration');
  divProvider := THTMLDiv.Create('divProvider');
  spanProvider := THTMLSpan.Create('spanProvider');
  spanProviderHeader := THTMLSpan.Create('spanProviderHeader');
  divDateTime := THTMLDiv.Create('divDateTime');
  spanDateTimeHeader := THTMLSpan.Create('spanDateTimeHeader');
  spanDateTime := THTMLSpan.Create('spanDateTime');
  divConfirm := THTMLDiv.Create('divConfirm');
  btnConfirm := TButton.Create('btnConfirm');
  spanBack := THTMLSpan.Create('spanBack');

  divPatient.BeforeLoadDFMValues;
  divPatientHolder.BeforeLoadDFMValues;
  divPatientInfo.BeforeLoadDFMValues;
  divNewPatient.BeforeLoadDFMValues;
  divLastName.BeforeLoadDFMValues;
  divFirstName.BeforeLoadDFMValues;
  divEmail.BeforeLoadDFMValues;
  divPhone.BeforeLoadDFMValues;
  divAppointInfo.BeforeLoadDFMValues;
  divOfficeInfo.BeforeLoadDFMValues;
  spanOffice.BeforeLoadDFMValues;
  spanTreatment.BeforeLoadDFMValues;
  divAppoint.BeforeLoadDFMValues;
  divDuration.BeforeLoadDFMValues;
  spanDurationHeader.BeforeLoadDFMValues;
  spanDuration.BeforeLoadDFMValues;
  divProvider.BeforeLoadDFMValues;
  spanProvider.BeforeLoadDFMValues;
  spanProviderHeader.BeforeLoadDFMValues;
  divDateTime.BeforeLoadDFMValues;
  spanDateTimeHeader.BeforeLoadDFMValues;
  spanDateTime.BeforeLoadDFMValues;
  divConfirm.BeforeLoadDFMValues;
  btnConfirm.BeforeLoadDFMValues;
  spanBack.BeforeLoadDFMValues;
  try
    Name := 'FormPatient';
    Width := 1183;
    Height := 734;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divPatient.SetParentComponent(Self);
    divPatient.Name := 'divPatient';
    divPatient.Left := 8;
    divPatient.Top := 8;
    divPatient.Width := 1167;
    divPatient.Height := 718;
    divPatient.ElementClassName := 'patient';
    divPatient.HeightStyle := ssAuto;
    divPatient.WidthStyle := ssAuto;
    divPatient.ElementPosition := epIgnore;
    divPatient.ElementFont := efCSS;
    divPatient.Role := '';
    divPatientHolder.SetParentComponent(divPatient);
    divPatientHolder.Name := 'divPatientHolder';
    divPatientHolder.Left := 3;
    divPatientHolder.Top := 3;
    divPatientHolder.Width := 1161;
    divPatientHolder.Height := 558;
    divPatientHolder.ElementClassName := 'patient-holder';
    divPatientHolder.HeightStyle := ssAuto;
    divPatientHolder.WidthStyle := ssAuto;
    divPatientHolder.ElementPosition := epIgnore;
    divPatientHolder.ElementFont := efCSS;
    divPatientHolder.Role := '';
    divPatientInfo.SetParentComponent(divPatientHolder);
    divPatientInfo.Name := 'divPatientInfo';
    divPatientInfo.Left := 3;
    divPatientInfo.Top := 3;
    divPatientInfo.Width := 724;
    divPatientInfo.Height := 550;
    divPatientInfo.ElementClassName := 'patient-info';
    divPatientInfo.HeightStyle := ssAuto;
    divPatientInfo.WidthStyle := ssAuto;
    divPatientInfo.ElementPosition := epIgnore;
    divPatientInfo.ElementFont := efCSS;
    divPatientInfo.Role := '';
    divNewPatient.SetParentComponent(divPatientInfo);
    divNewPatient.Name := 'divNewPatient';
    divNewPatient.Left := 50;
    divNewPatient.Top := 57;
    divNewPatient.Width := 257;
    divNewPatient.Height := 41;
    divNewPatient.ElementClassName := 'edt-holder';
    divNewPatient.HeightStyle := ssAuto;
    divNewPatient.WidthStyle := ssAuto;
    divNewPatient.ElementPosition := epIgnore;
    divNewPatient.ElementFont := efCSS;
    divNewPatient.HTML.BeginUpdate;
    try
      divNewPatient.HTML.Clear;
      divNewPatient.HTML.Add('<label for="new-patient" class="label"><h6>Are you a new Patient? *</h6></label>');
      divNewPatient.HTML.Add('<select name="new-patient" id="select-new-patient" class="question-inputfield">');
      divNewPatient.HTML.Add('  <option value="yes">Yes</option>');
      divNewPatient.HTML.Add('  <option value="no">No</option>');
      divNewPatient.HTML.Add('</select>');
    finally
      divNewPatient.HTML.EndUpdate;
    end;
    divNewPatient.Role := '';
    divLastName.SetParentComponent(divPatientInfo);
    divLastName.Name := 'divLastName';
    divLastName.Left := 50;
    divLastName.Top := 251;
    divLastName.Width := 252;
    divLastName.Height := 40;
    divLastName.ElementClassName := 'edt-holder';
    divLastName.HeightStyle := ssAuto;
    divLastName.WidthStyle := ssAuto;
    divLastName.ChildOrder := 2;
    divLastName.ElementPosition := epIgnore;
    divLastName.ElementFont := efCSS;
    divLastName.HTML.BeginUpdate;
    try
      divLastName.HTML.Clear;
      divLastName.HTML.Add('<label for="last" class="label"><h6>Enter your Last Name *</h6></label>');
      divLastName.HTML.Add('<input name="last" id="last-name" class="question-inputfield" autocomplete="family-name"></input>');
    finally
      divLastName.HTML.EndUpdate;
    end;
    divLastName.Role := '';
    divFirstName.SetParentComponent(divPatientInfo);
    divFirstName.Name := 'divFirstName';
    divFirstName.Left := 50;
    divFirstName.Top := 153;
    divFirstName.Width := 252;
    divFirstName.Height := 40;
    divFirstName.ElementClassName := 'edt-holder';
    divFirstName.HeightStyle := ssAuto;
    divFirstName.WidthStyle := ssAuto;
    divFirstName.ChildOrder := 1;
    divFirstName.ElementPosition := epIgnore;
    divFirstName.ElementFont := efCSS;
    divFirstName.HTML.BeginUpdate;
    try
      divFirstName.HTML.Clear;
      divFirstName.HTML.Add('<label for="first" class="label"><h6>Enter your First Name *</h6></label>');
      divFirstName.HTML.Add('<input name="first" id="first-name" class="question-inputfield" autocomplete="given-name"></input>');
    finally
      divFirstName.HTML.EndUpdate;
    end;
    divFirstName.Role := '';
    divEmail.SetParentComponent(divPatientInfo);
    divEmail.Name := 'divEmail';
    divEmail.Left := 50;
    divEmail.Top := 348;
    divEmail.Width := 252;
    divEmail.Height := 41;
    divEmail.ElementClassName := 'edt-holder';
    divEmail.HeightStyle := ssAuto;
    divEmail.WidthStyle := ssAuto;
    divEmail.ChildOrder := 3;
    divEmail.ElementPosition := epIgnore;
    divEmail.ElementFont := efCSS;
    divEmail.HTML.BeginUpdate;
    try
      divEmail.HTML.Clear;
      divEmail.HTML.Add('<label for="email" class="label"><h6>Enter your Email Address *</h6></label>');
      divEmail.HTML.Add('<input name="email" id="email" class="question-inputfield" type="email" autocomplete="email"></input>');
    finally
      divEmail.HTML.EndUpdate;
    end;
    divEmail.Role := '';
    divPhone.SetParentComponent(divPatientInfo);
    divPhone.Name := 'divPhone';
    divPhone.Left := 50;
    divPhone.Top := 440;
    divPhone.Width := 252;
    divPhone.Height := 41;
    divPhone.ElementClassName := 'edt-holder';
    divPhone.HeightStyle := ssAuto;
    divPhone.WidthStyle := ssAuto;
    divPhone.ChildOrder := 4;
    divPhone.ElementPosition := epIgnore;
    divPhone.ElementFont := efCSS;
    divPhone.HTML.BeginUpdate;
    try
      divPhone.HTML.Clear;
      divPhone.HTML.Add('<label for="phone" class="label"><h6>Enter your Phone Number *</h6></label>');
      divPhone.HTML.Add('<input name="phone" id="phone" class="question-inputfield" autocomplete="tel"></input>');
    finally
      divPhone.HTML.EndUpdate;
    end;
    divPhone.Role := '';
    divAppointInfo.SetParentComponent(divPatientHolder);
    divAppointInfo.Name := 'divAppointInfo';
    divAppointInfo.Left := 730;
    divAppointInfo.Top := 3;
    divAppointInfo.Width := 428;
    divAppointInfo.Height := 550;
    divAppointInfo.ElementClassName := 'appoint-info';
    divAppointInfo.HeightStyle := ssAuto;
    divAppointInfo.WidthStyle := ssAuto;
    divAppointInfo.ChildOrder := 1;
    divAppointInfo.ElementPosition := epIgnore;
    divAppointInfo.ElementFont := efCSS;
    divAppointInfo.Role := '';
    divOfficeInfo.SetParentComponent(divAppointInfo);
    divOfficeInfo.Name := 'divOfficeInfo';
    divOfficeInfo.Left := 3;
    divOfficeInfo.Top := 3;
    divOfficeInfo.Width := 422;
    divOfficeInfo.Height := 118;
    divOfficeInfo.ElementClassName := 'office-info-holder';
    divOfficeInfo.HeightStyle := ssAuto;
    divOfficeInfo.WidthStyle := ssAuto;
    divOfficeInfo.ElementPosition := epIgnore;
    divOfficeInfo.ElementFont := efCSS;
    divOfficeInfo.Role := '';
    spanOffice.SetParentComponent(divOfficeInfo);
    spanOffice.Name := 'spanOffice';
    spanOffice.Left := 3;
    spanOffice.Top := 3;
    spanOffice.Width := 416;
    spanOffice.Height := 45;
    spanOffice.HeightStyle := ssAuto;
    spanOffice.WidthStyle := ssAuto;
    spanOffice.ElementPosition := epIgnore;
    spanOffice.ElementFont := efCSS;
    spanOffice.Role := '';
    spanTreatment.SetParentComponent(divOfficeInfo);
    spanTreatment.Name := 'spanTreatment';
    spanTreatment.Left := 3;
    spanTreatment.Top := 54;
    spanTreatment.Width := 416;
    spanTreatment.Height := 61;
    spanTreatment.HeightStyle := ssAuto;
    spanTreatment.WidthStyle := ssAuto;
    spanTreatment.ChildOrder := 1;
    spanTreatment.ElementPosition := epIgnore;
    spanTreatment.ElementFont := efCSS;
    spanTreatment.Role := '';
    divAppoint.SetParentComponent(divAppointInfo);
    divAppoint.Name := 'divAppoint';
    divAppoint.Left := 3;
    divAppoint.Top := 192;
    divAppoint.Width := 422;
    divAppoint.Height := 353;
    divAppoint.ElementClassName := 'appoint-info-holder';
    divAppoint.HeightStyle := ssAuto;
    divAppoint.WidthStyle := ssAuto;
    divAppoint.ChildOrder := 1;
    divAppoint.ElementPosition := epIgnore;
    divAppoint.ElementFont := efCSS;
    divAppoint.Role := '';
    divDuration.SetParentComponent(divAppoint);
    divDuration.Name := 'divDuration';
    divDuration.Left := 3;
    divDuration.Top := 3;
    divDuration.Width := 416;
    divDuration.Height := 118;
    divDuration.ElementClassName := 'holder';
    divDuration.HeightStyle := ssAuto;
    divDuration.WidthStyle := ssAuto;
    divDuration.ElementPosition := epIgnore;
    divDuration.ElementFont := efCSS;
    divDuration.Role := '';
    spanDurationHeader.SetParentComponent(divDuration);
    spanDurationHeader.Name := 'spanDurationHeader';
    spanDurationHeader.Left := 3;
    spanDurationHeader.Top := 3;
    spanDurationHeader.Width := 410;
    spanDurationHeader.Height := 41;
    spanDurationHeader.ElementClassName := 'header';
    spanDurationHeader.HeightStyle := ssAuto;
    spanDurationHeader.WidthStyle := ssAuto;
    spanDurationHeader.ElementPosition := epIgnore;
    spanDurationHeader.ElementFont := efCSS;
    spanDurationHeader.HTML.BeginUpdate;
    try
      spanDurationHeader.HTML.Clear;
      spanDurationHeader.HTML.Add('<span class="material-symbols-outlined">hourglass </span><p>Duration</p>');
    finally
      spanDurationHeader.HTML.EndUpdate;
    end;
    spanDurationHeader.Role := '';
    spanDuration.SetParentComponent(divDuration);
    spanDuration.Name := 'spanDuration';
    spanDuration.Left := 3;
    spanDuration.Top := 48;
    spanDuration.Width := 410;
    spanDuration.Height := 67;
    spanDuration.ElementClassName := 'content';
    spanDuration.HeightStyle := ssAuto;
    spanDuration.WidthStyle := ssAuto;
    spanDuration.ChildOrder := 1;
    spanDuration.ElementPosition := epIgnore;
    spanDuration.ElementFont := efCSS;
    spanDuration.Role := '';
    divProvider.SetParentComponent(divAppoint);
    divProvider.Name := 'divProvider';
    divProvider.Left := 3;
    divProvider.Top := 232;
    divProvider.Width := 416;
    divProvider.Height := 118;
    divProvider.ElementClassName := 'holder';
    divProvider.HeightStyle := ssAuto;
    divProvider.WidthStyle := ssAuto;
    divProvider.ChildOrder := 2;
    divProvider.ElementPosition := epIgnore;
    divProvider.ElementFont := efCSS;
    divProvider.Role := '';
    spanProvider.SetParentComponent(divProvider);
    spanProvider.Name := 'spanProvider';
    spanProvider.Left := 3;
    spanProvider.Top := 48;
    spanProvider.Width := 410;
    spanProvider.Height := 67;
    spanProvider.ElementClassName := 'content';
    spanProvider.HeightStyle := ssAuto;
    spanProvider.WidthStyle := ssAuto;
    spanProvider.ChildOrder := 1;
    spanProvider.ElementPosition := epIgnore;
    spanProvider.ElementFont := efCSS;
    spanProvider.Role := '';
    spanProviderHeader.SetParentComponent(divProvider);
    spanProviderHeader.Name := 'spanProviderHeader';
    spanProviderHeader.Left := 3;
    spanProviderHeader.Top := 3;
    spanProviderHeader.Width := 410;
    spanProviderHeader.Height := 41;
    spanProviderHeader.ElementClassName := 'header';
    spanProviderHeader.HeightStyle := ssAuto;
    spanProviderHeader.WidthStyle := ssAuto;
    spanProviderHeader.ElementPosition := epIgnore;
    spanProviderHeader.ElementFont := efCSS;
    spanProviderHeader.HTML.BeginUpdate;
    try
      spanProviderHeader.HTML.Clear;
      spanProviderHeader.HTML.Add('<span class="material-symbols-outlined">person</span><p>Provider</p>');
    finally
      spanProviderHeader.HTML.EndUpdate;
    end;
    spanProviderHeader.Role := '';
    divDateTime.SetParentComponent(divAppoint);
    divDateTime.Name := 'divDateTime';
    divDateTime.Left := 3;
    divDateTime.Top := 124;
    divDateTime.Width := 416;
    divDateTime.Height := 102;
    divDateTime.ElementClassName := 'holder';
    divDateTime.HeightStyle := ssAuto;
    divDateTime.WidthStyle := ssAuto;
    divDateTime.ChildOrder := 1;
    divDateTime.ElementPosition := epIgnore;
    divDateTime.ElementFont := efCSS;
    divDateTime.Role := '';
    spanDateTimeHeader.SetParentComponent(divDateTime);
    spanDateTimeHeader.Name := 'spanDateTimeHeader';
    spanDateTimeHeader.Left := 3;
    spanDateTimeHeader.Top := 3;
    spanDateTimeHeader.Width := 410;
    spanDateTimeHeader.Height := 41;
    spanDateTimeHeader.ElementClassName := 'header';
    spanDateTimeHeader.HeightStyle := ssAuto;
    spanDateTimeHeader.WidthStyle := ssAuto;
    spanDateTimeHeader.ElementPosition := epIgnore;
    spanDateTimeHeader.ElementFont := efCSS;
    spanDateTimeHeader.HTML.BeginUpdate;
    try
      spanDateTimeHeader.HTML.Clear;
      spanDateTimeHeader.HTML.Add('<span class="material-symbols-outlined">calendar_today </span><p>Appointment Time</p>');
    finally
      spanDateTimeHeader.HTML.EndUpdate;
    end;
    spanDateTimeHeader.Role := '';
    spanDateTime.SetParentComponent(divDateTime);
    spanDateTime.Name := 'spanDateTime';
    spanDateTime.Left := 3;
    spanDateTime.Top := 50;
    spanDateTime.Width := 410;
    spanDateTime.Height := 49;
    spanDateTime.ElementClassName := 'content';
    spanDateTime.HeightStyle := ssAuto;
    spanDateTime.WidthStyle := ssAuto;
    spanDateTime.ChildOrder := 1;
    spanDateTime.ElementPosition := epIgnore;
    spanDateTime.ElementFont := efCSS;
    spanDateTime.Role := '';
    divConfirm.SetParentComponent(divPatient);
    divConfirm.Name := 'divConfirm';
    divConfirm.Left := 3;
    divConfirm.Top := 562;
    divConfirm.Width := 1161;
    divConfirm.Height := 153;
    divConfirm.ElementClassName := 'confirm-holder';
    divConfirm.HeightStyle := ssAuto;
    divConfirm.WidthStyle := ssAuto;
    divConfirm.ChildOrder := 1;
    divConfirm.ElementPosition := epIgnore;
    divConfirm.ElementFont := efCSS;
    divConfirm.Role := '';
    btnConfirm.SetParentComponent(divConfirm);
    btnConfirm.Name := 'btnConfirm';
    btnConfirm.Left := 1000;
    btnConfirm.Top := 72;
    btnConfirm.Width := 96;
    btnConfirm.Height := 25;
    btnConfirm.Caption := 'Submit';
    btnConfirm.ChildOrder := 1;
    btnConfirm.ElementClassName := 'btn-confirm';
    btnConfirm.ElementFont := efCSS;
    btnConfirm.ElementPosition := epIgnore;
    btnConfirm.HeightStyle := ssAuto;
    btnConfirm.HeightPercent := 100.000000000000000000;
    btnConfirm.WidthStyle := ssAuto;
    btnConfirm.WidthPercent := 100.000000000000000000;
    SetEvent(btnConfirm, Self, 'OnClick', 'btnConfirmClick');
    spanBack.SetParentComponent(divConfirm);
    spanBack.Name := 'spanBack';
    spanBack.Left := 53;
    spanBack.Top := 64;
    spanBack.Width := 100;
    spanBack.Height := 41;
    spanBack.ElementClassName := 'back';
    spanBack.HeightStyle := ssAuto;
    spanBack.WidthStyle := ssAuto;
    spanBack.ElementPosition := epIgnore;
    spanBack.ElementFont := efCSS;
    spanBack.HTML.BeginUpdate;
    try
      spanBack.HTML.Clear;
      spanBack.HTML.Add('<h6>Back</h6>');
    finally
      spanBack.HTML.EndUpdate;
    end;
    spanBack.Role := '';
    SetEvent(spanBack, Self, 'OnClick', 'spanBackClick');
  finally
    divPatient.AfterLoadDFMValues;
    divPatientHolder.AfterLoadDFMValues;
    divPatientInfo.AfterLoadDFMValues;
    divNewPatient.AfterLoadDFMValues;
    divLastName.AfterLoadDFMValues;
    divFirstName.AfterLoadDFMValues;
    divEmail.AfterLoadDFMValues;
    divPhone.AfterLoadDFMValues;
    divAppointInfo.AfterLoadDFMValues;
    divOfficeInfo.AfterLoadDFMValues;
    spanOffice.AfterLoadDFMValues;
    spanTreatment.AfterLoadDFMValues;
    divAppoint.AfterLoadDFMValues;
    divDuration.AfterLoadDFMValues;
    spanDurationHeader.AfterLoadDFMValues;
    spanDuration.AfterLoadDFMValues;
    divProvider.AfterLoadDFMValues;
    spanProvider.AfterLoadDFMValues;
    spanProviderHeader.AfterLoadDFMValues;
    divDateTime.AfterLoadDFMValues;
    spanDateTimeHeader.AfterLoadDFMValues;
    spanDateTime.AfterLoadDFMValues;
    divConfirm.AfterLoadDFMValues;
    btnConfirm.AfterLoadDFMValues;
    spanBack.AfterLoadDFMValues;
  end;
end;

end.