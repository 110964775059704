unit Types_Vars_API;

interface

uses
  //
  Types_Vars_Security,
  //
  System.Classes, JS, WebLib.Dialogs, WebLib.Controls, WebLib.REST, WEB,
  WebLib.Forms, system.SysUtils, Generics.Collections;

const

  { Appt Queries }
  gcsApptByAccount = '/Data/appointments/byAccount?';
  gcsApptByPatient = '/Data/appointments/byPatient?';
  gcsApptByRange = '/Data/appointments/schedule/byRange?';
  gcsApptByCounter = '/Data/appointments/byCounter?';
  gcsContactByCounter = '/Data/appointments/GetContactbyCounter?';
  gcsBookSetup = '/Data/appointments/schedule/BookSetup';
  gcsGenApptMap = '/Data/appointments/Schedule/GenerateApptmap';
  gcsApptMap = '/Data/appointments/Schedule/ApptMap?';
  gcsHoliday = '/Data/appointments/Schedule/Holidays';
  gcsProviders = '/Data/appointments/Schedule/Providers';
  gcsFamilyRecall = '/Data/appointments/FamilyRecallStatus?';
  gcsRescheduleAppt = '/Data/appointments/Reschedule';
  gcsApptAction = '/Data/appointments/Actions?';

  { Appt Types }
  gciApptEverything = 1;
  gciApptOnly = 2;
  gciApptRecalls = 3;
  gciApptContacts = 4;

  { Patient Queries }
  gcsPatByAccount = '/Data/Patients/byAccount?';
  gcsPatByName = '/Data/Patients/byName?';
  gcsPatByBalance = '/Data/Patients/AccountBalance?';
  gcsPatByPhone = '/Data/Patients/ByPhone?';
  gcsPatByPatNum = '/Data/Patients/ByPatient_Basic?';
  gcsPatGetAddress = '/Data/Patients/GetAddresses?';
  gcsPatGetPhones = '/Data/Patients/GetPhones?';
  gcsPatGetNotes = '/Data/Patients/GetNotes?';
  gcsPatGetBillParty = '/Data/Patients/GetBillingParty?';
  gcsPatGetCoverages = '/Data/Patients/GetCoverages?';
  gcsPatGetCovDetails = '/Data/Patients/GetCoveragesDetail?';

  { Ledger Queries }
  gcsAccountGetAgings = '/Data/Patients/AccountBalance?';
  gcsGetLedger = '/Data/Accounting/Ledger?';
  gcsGetTreatments = '/Data/Accounting/GetTreatments?';

  { General Queries }
  gcsScreenSetup = '/Data/General/GetScreenSetup';
  gcsInsertLog = '/Data/General/InsertLog';
  gcsGetCodes = '/Data/General/GetCodes?';

  { Report Queries }
  gcsGenerateReport = '/Data/Reports/Reports';
  gcsDailyReport = '/Data/report_daily';
  gcsDailySummary = '/Data/report_daily_summary';
  gcsProductionReport = '/Data/report_production_provider';
  gcsProductionOffice = '/Data/report_production_office';
  gcsARProvider = '/Data/report_ar_provider';
  gcsARRunDate = '/data/report_list?$filter=ReportType eq 4&$orderby=LastRunTime desc&$top=1';
  gcsServiceCode = '/Data/report_production_service';
  gcsServiceCategory = '/Data/report_production_servicecategory';
  gcsReferral = '/Data/report_sh_referral';
  gcsReferralDetail = '/Data/report_sh_referraldetail';

  { Security Queries }
  gcsLogin = '/data/securities/userlogin?';
  gcsUpdatePassword = '/data/securities/updatepassword?';
  gcsUpdateOfficeCon = '/data/securities/UpdateOfficeConnection?';
  gcsGetOfficeCon = '/data/securities/GetOfficeConnections';
  gcsDelOfficeCon = '/Data/securities/DeleteOfficeConnection?';
  gcsGetUserList = '/Data/securities/GetUserList';
  gcsGetGroupList = '/Data/securities/GetGroupList';
  gcsGetGroupPermissions = '/Data/securities/GetGroupPermissions?';
  gcsGetUser = '/Data/securities/GetUser?';
  gcsNewUser = '/Data/securities/AddNewUser';
  gcsNewSecurityGroup = '/Data/securities/AddNewGroup';
  gcsUpdateUser = '/Data/securities/UpdateUser';
  gcsUpdateSecurityGroup = '/Data/securities/UpdateGroup';
  gcsResetPassword = '/Data/securities/ResetPassword';

  { Registration Queries }
  gcsGetRegisteredUser = '/data/OpenPorte/GetRegisteredUser?';
  gcsCreatePassReset = '/Data/OpenPorte/RequestPasswordReset?';
  gcsGetPassReset = '/Data/OpenPorte/ProcessPasswordReset?';

  { Appointment Request Types }
  gcsHolidayRequest = 'HO';
  gcsProvidersRequest = 'PR';
  gcsGenApptMapRequest = 'GM';
  gcsApptMapRequest = 'AM';
  gcsBookSetupRequest = 'BS';
  gcsApptRangeRequest = 'AR';
  gcsApptByPatientRequest = 'AP';
  gcsApptByAccountRequest = 'AA';
  gcsContactsByCounterRequest = 'CC';
  gcsFamilyRecallRequest = 'FR';
  gcsRescheduleApptRequest = 'RA';
  gcsApptActionRequest = 'AC';

  { Patient Request Types }
  gcsPatByAccountRequest = 'PA';
  gcsPatByNameRequest = 'PN';
  gcsPatByPatNumRequest = 'PNO';
  gcsPatByPhoneRequest = 'PPH';
  gcsPatGetAddressRequest = 'PGA';
  gcsPatGetNotesRequest = 'PGN';
  gcsPatGetPhonesRequest = 'PGP';
  gcsPatGetBillPartyRequest = 'PBP';
  gcsPatGetCoveragesRequest = 'PGC';
  gcsPatGetCovDetailsRequest = 'PCD';

  { Ledger Request Types }
  gcsAccountGetAgingsRequest = 'AAG';
  gcsGetLedgerRequest = 'LG';
  gcsGetTreatmentsRequest = 'TRT';

  { General Request Types }
  gcsScreenSetupRequest = 'SS';
  gcsLoginRequest = 'LR';
  gcsUpdatePassRequest = 'UP';
  gcsUpdateOfficeConRequest = 'UO';
  gcsGetOfficeConRequest = 'GO';
  gcsInsertLogRequest = 'LOG';
  gcsGetCodesRequest = 'GCD';

  { Appointment JSON keys }
  gcsApptCounter = 'ApptCounter';
  gcsApptPatNum = 'PatientNumber';
  gcsApptAccount = 'Account';
  gcsApptPatName = 'ApptPatientName';
  gcsApptHome = 'ApptHomePhone';
  gcsApptWork = 'ApptWorkPhone';
  gcsApptCell = 'ApptCellPhone';
  gcsApptProv = 'Provider';
  gcsApptAssocProv = 'AssociateProvider';
  gcsApptStart = 'ApptStartDateTime';
  gcsApptEnd = 'ApptEndDateTime';
  gcsApptDuration = 'ApptDuration';
  gcsApptDesc = 'ApptDescription';
  gcsApptJob = 'ApptJob';
  gcsApptMedAlert = 'IsApptMedicalAlert';
  gcsApptNewPat = 'IsApptNewpatient';
  gcsApptStatus = 'ApptStatus';
  gcsApptStatus_1 = 'ApptStatus_1';
  gcsApptSubStatus = 'ApptSubstatus';
  gcsApptASAP = 'IsApptASAP';
  gcsApptPreConReq = 'IsPreConfirmRequired';
  gcsApptLabReq = 'IsLabRequired';
  gcsApptLabArrive = 'IslabArrived';
  gcsApptChair = 'ApptChair';
  gcsApptTime = 'ApptTime';
  gcsApptTimeAMPM = 'ApptTimeWithAMPM';
  gcsApptDate = 'ApptDate';
  gcsApptStatusDesc = 'ApptStatusDescription';
  gcsApptNote = 'ApptNote';

  { BookSetup JSON keys }
  gcsDisplayStart = 'DisplayStart';
  gcsDisplayUnit = 'DisplayUnit';
  gcsDisplayEnd = 'DisplayEnd';
  gcsStartHour = 'StartHour';
  gcsEndHour = 'EndHour';
  gcsBookRowHeight = 'BookRowHeight';
  gcsBookColumn = 'BookColumn';
  gcsColorCodeBy = 'ColorCodeby';
  gcsBlockedColor = 'BlockEdColor';
  gcsReservedColor = 'ReservedColor';
  gcsSelectedColor = 'SelectedColor';
  gcsConfirmColor = 'ConfirmColor';
  gcsPreConfirmColor = 'PreConfirmColor';

  { Screen Setup JSON keys }
  gcsEditBox = 'EditBox';
  gcsEditBoxLabel = 'EditBoxLabel';
  gcsGridCaption = 'GridCaption';
  gcsGridFont = 'GridFont';
  gcsTabCaption = 'TabCaption';
  gcsScreenRowHeight = 'RowHeight';
  gcsColorFont = 'ColorFont';
  gcsSizeFont = 'SizeFont';

  { Office List JSON keys }
  gcsOfficeName = 'OfficeName';
  gcsOfficeSeq = 'OfficeSequence';
  gcsConString = 'IP';
  gcsPort = 'Port';

  { Providers }
  gcsIsActive = 'IsActive';
  gcsColumnSequence = 'ColumnSequence';
  gcsProvider = 'Provider';
  gcsName = 'Name';
  gcsShortName = 'ShortName';
  gcsIsHygienist = 'IsHygienist';
  gcsColumnColor = 'ColumnColor';

Type
  tApptActionType = (tApptNone, tApptASAP, tApptConfirm, tApptPreconfirm,
    tApptPreConfirmRequire, tApptComplete, tApptArrive, tApptBill, tApptLabArrive,
    tApptLabRequire, tAppointment, tNonAppointment);
  //
  TclsOfficeInfo = class
  public
    APIToken: String;
    APIPassword: String;
    UserID: Integer;
    OfficeName: String;
    OfficeSequence: Integer;
    ConnectionString: String;
    Port: Integer;
    MultiOfficeIndex: Integer;
    IP: String;
    RestServerVersion: Integer;
  end;

  TclsLogicHTTPRequest = class(THTTPRequest)
  public
    Procedure HTTPRequestError(Sender: TObject; ARequest: TJSXMLHttpRequestRecord;
      Event: TJSEventRecord; var Handled: boolean);
    Procedure AddHeaders(aiOfficeSequence, aiUserID, aiModule: Integer;
      asAPIToken, asAPIPassword: string);
    Procedure SetConnectionString(aOffice: TclsOfficeInfo; asRequestType: string); overload;
    Procedure SetConnectionString(aOffice: TclsOfficeInfo; asRequestType, aString: string); overload;
    Procedure SetConnectionString(aOffice: TclsOfficeInfo; asRequestType, asProvFrom,
      asProvTo: String; aStartDate, aEndDate: TDateTime); overload;
    Procedure SetConnectionString(aOffice: TclsOfficeInfo; asRequestType, aString1,
      aString2: String); overload;
    Procedure SetConnectionString(aOffice: TclsOfficeInfo; asRequestType: string;
      aNumber: integer); overload;
    Procedure SetConnectionString(aOffice: TclsOfficeInfo; asRequestType: string;
      aNumber1, aNumber2: integer); overload;
    Procedure SetConnectionString(aOffice: TclsOfficeInfo; asRequestType: string;
      aAccount, aPatNum, aTypeNum: integer); overload;
    Procedure SetConnectionString(aOffice: TclsOfficeInfo; asRequestType, aString1,
      aString2, aString3: String); overload;
    Procedure ProcessResponseError(asErrorString: string);
    Procedure ResponseTimeOut(Sender: TObject);
  private
    ConnectionString: String;
    ServerString: String;
    [async]
    Procedure ShowErrorDialog(aErrorMessage: string); async;
  end;

  Type

  TclsApptProvider = class
    IsActive: Boolean;
    ColumnSequence: Integer;
    Provider: String;
    Name: String;
    ShortName: String;
    IsHygienist: Boolean;
    ColumnColor: Integer;
  end;

  TclsApptSchedProvider = class(TclsApptProvider)
    ScheduleIndex: Integer;
    Chair: Integer;
    OfficeSequence: Integer;
  end;

  tlstProviders = TList<TclsApptSchedProvider>;

  TclsOfficeProviders = class
    lstOfficeProviders: tlstProviders;
  end;

  tOfficeArray = array of TclsOfficeInfo;

  procedure SendLogMessage(aOffice: TclsOfficeInfo; aLogMessage: String);
  function APIDateStringToDate(aDateString: string): TDateTime;

implementation

{ TclsLogicHTTPRequest }

procedure TclsLogicHTTPRequest.AddHeaders(aiOfficeSequence, aiUserID, aiModule: Integer;
  asAPIToken, asAPIPassword: string);
begin
  With Self.Headers do
  begin
    AddPair('UserID', IntToStr(aiUserID));
    AddPair('OfficeNumber', IntToStr(aiOfficeSequence));
    AddPair('APIToken', asAPIToken);
    AddPair('APIPassword', asAPIPassword);
    AddPair('Module', IntToStr(aiModule));
  end;
end;

Procedure TclsLogicHTTPRequest.ProcessResponseError(asErrorString: string);
var
  jsError: TJSObject;
  sErrorMessage: String;
begin
  jsError := TJSObject(TJSObject(TJSJSON.parse(asErrorString))['error']);
  sErrorMessage := 'Error processing request. Error Code: ' +
    string(jsError['code']) + ' Error Message: ' + string(jsError['message']);
  ShowErrorDialog(sErrorMessage);
end;

procedure TclsLogicHTTPRequest.ResponseTimeOut(Sender: TObject);
begin
  console.log('HTTP request timeout from ' + Self.ServerString);
  ShowErrorDialog(Self.ServerString + ' took too long to respond, check server connection');
end;

procedure TclsLogicHTTPRequest.HTTPRequestError(Sender: TObject;
  ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord;
  var Handled: boolean);
begin
  console.log('HTTP request error from ' + Self.ServerString);
  ShowErrorDialog('Error connecting to server ' + Self.ServerString);
  Handled := True;
end;

{ Connection string requiring provider range strings and date range dates as parameters }
procedure TclsLogicHTTPRequest.SetConnectionString(aOffice: TclsOfficeInfo;
  asRequestType, asProvFrom, asProvTo: String; aStartDate, aEndDate: TDateTime);
begin
  if asRequestType = gcsApptRangeRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port) + gcsApptByRange +
      'FromProvider=' + asProvFrom + '&ToProvider=' + asProvTo + '&FromDate=' +
      FormatDateTime('yyyy-MM-dd', aStartDate) + '&ToDate=' +
      FormatDateTime('yyyy-MM-dd', aEndDate);
  if asRequestType = gcsApptMapRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port) + gcsApptMap +
      'FromProvider=' + asProvFrom + '&ToProvider=' + asProvTo + '&FromDate=' +
      FormatDateTime('yyyy-MM-dd', aStartDate) + '&ToDate=' +
      FormatDateTime('yyyy-MM-dd', aEndDate);

  Self.URL := Self.ConnectionString;
  Self.ServerString := aOffice.ConnectionString + IntToStr(aOffice.Port);
  Self.OnError := Self.HTTPRequestError;
  Self.OnTimeout := Self.ResponseTimeOut;
end;

{ Connection string with no parameters }
procedure TclsLogicHTTPRequest.SetConnectionString(aOffice: TclsOfficeInfo;
  asRequestType: string);
begin
  if asRequestType = gcsHolidayRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsHoliday;
  if asRequestType = gcsBookSetupRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsBookSetup;
  if asRequestType = gcsProvidersRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsProviders;
  if asRequestType = gcsGenApptMapRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsGenApptMap;
  if asRequestType = gcsScreenSetupRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsScreenSetup;
  if asRequestType = gcsRescheduleApptRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsRescheduleAppt;
  if asRequestType = gcsUpdatePassRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsUpdatePassword;
  if asRequestType = gcsGetOfficeConRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsGetOfficeCon;
  if asRequestType = gcsInsertLogRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsInsertLog;

  Self.URL := Self.ConnectionString;
  Self.ServerString := aOffice.ConnectionString + IntToStr(aOffice.Port);
  Self.OnError := Self.HTTPRequestError;
  Self.OnTimeout := Self.ResponseTimeOut;
end;

{ Connection string requiring first name and last name string parameters }
procedure TclsLogicHTTPRequest.SetConnectionString(aOffice: TclsOfficeInfo;
  asRequestType, aString1, aString2: String);
begin
  if asRequestType = gcsPatByNameRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsPatByName + 'LastName=' + aString1 + '%&FirstName=' + aString2 + '%';
  if asRequestType = gcsLoginRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsLogin + 'useremail=' + aString1 + '&hashedpassword=' + aString2;

  Self.URL := Self.ConnectionString;
  Self.ServerString := aOffice.ConnectionString + IntToStr(aOffice.Port);
  Self.OnError := Self.HTTPRequestError;
  Self.OnTimeout := Self.ResponseTimeOut;
end;

{ Connection string requiring single integer parameter }
procedure TclsLogicHTTPRequest.SetConnectionString(aOffice: TclsOfficeInfo;
  asRequestType: string; aNumber: integer);
begin
  if asRequestType = gcsPatGetBillPartyRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsPatGetBillParty + 'Account=' + IntToStr(aNumber);
  if asRequestType = gcsPatByAccountRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsPatByAccount + 'Account=' + IntToStr(aNumber);
  if asRequestType = gcsPatByPatNumRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsPatByPatNum + 'PatientNumber=' + IntToStr(aNumber);
  if asRequestType = gcsFamilyRecallRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsFamilyRecall + 'Account=' + IntToStr(aNumber);
  if asRequestType = gcsAccountGetAgingsRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsAccountGetAgings + 'Account=' + IntToStr(aNumber);
  if asRequestType = gcsPatByPhoneRequest then
  begin
    if ANumber > 999999 then
      Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
        + gcsPatByPhone + 'Phone=' + IntToStr(ANumber)
    else
      Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
        + gcsPatByPhone + 'Phone=' + IntToStr(ANumber) + '%';
  end;

  Self.URL := Self.ConnectionString;
  Self.ServerString := aOffice.ConnectionString + IntToStr(aOffice.Port);
  Self.OnError := Self.HTTPRequestError;
  Self.OnTimeout := Self.ResponseTimeOut;
end;

{ Connection string requiring 2 integers, ie. a number and type or account
  and patient numbers }
procedure TclsLogicHTTPRequest.SetConnectionString(aOffice: TclsOfficeInfo;
  asRequestType: string; aNumber1, aNumber2: integer);
begin
  if asRequestType = gcsApptByPatientRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsApptByPatient + 'PatientNumber=' + IntToStr(aNumber1) + '&ShowType='
      + IntToStr(aNumber2);
  if asRequestType = gcsApptByAccountRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsApptByAccount + 'Account=' + IntToStr(aNumber1) + '&ShowType='
      + IntToStr(aNumber2);
  if asRequestType = gcsPatGetCoveragesRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsPatGetCoverages + 'Account=' + IntToStr(aNumber1) + '&PatientNumber='
      + IntToStr(aNumber2);
  if asRequestType = gcsPatGetCovDetailsRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsPatGetCovDetails + 'Account=' + IntToStr(aNumber1) + '&PatientNumber='
      + IntToStr(aNumber2);
  if asRequestType = gcsGetTreatmentsRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsGetTreatments + 'InvoiceNumber=' + IntToStr(aNumber1) + '&LineNumber='
      + IntToStr(aNumber2);
    if asRequestType = gcsApptActionRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsApptAction + 'Counter=' + IntToStr(aNumber1) + '&ActionType='
      + IntToStr(aNumber2);

  Self.URL := Self.ConnectionString;
  Self.ServerString := aOffice.ConnectionString + IntToStr(aOffice.Port);
  Self.OnError := Self.HTTPRequestError;
  Self.OnTimeout := Self.ResponseTimeOut;
end;

{ Connection string requiring account, patient number, and a type number ie.
  Addresses, Phones, and Notes }
procedure TclsLogicHTTPRequest.SetConnectionString(aOffice: TclsOfficeInfo;
  asRequestType: string; aAccount, aPatNum, aTypeNum: integer);
begin
  if asRequestType = gcsPatGetAddressRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsPatGetAddress + 'Account=' + IntToStr(aAccount) + '&PatientNumber='
      + IntToStr(aPatNum) + '&AddressType=' + IntToStr(aTypeNum);
  if asRequestType = gcsPatGetPhonesRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsPatGetPhones + 'Account=' + IntToStr(aAccount) + '&PatientNumber='
      + IntToStr(aPatNum) + '&PhoneType=' + IntToStr(aTypeNum);
  if asRequestType = gcsPatGetNotesRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsPatGetNotes + 'Account=' + IntToStr(aAccount) + '&PatientNumber='
      + IntToStr(aPatNum) + '&NoteType=' + IntToStr(aTypeNum);
  if asRequestType = gcsGetLedgerRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsGetLedger + 'Account=' + IntToStr(aAccount) + '&PatientNumber='
      + IntToStr(aPatNum) + '&LoadPeriod=' + IntToStr(aTypeNum);

  Self.URL := Self.ConnectionString;
  Self.ServerString := aOffice.ConnectionString + IntToStr(aOffice.Port);
  Self.OnError := Self.HTTPRequestError;
  Self.OnTimeout := Self.ResponseTimeOut;
end;

{ Connection string requring 3 string value parameters }
procedure TclsLogicHTTPRequest.SetConnectionString(aOffice: TclsOfficeInfo;
  asRequestType, aString1, aString2, aString3: String);
begin
  if asRequestType = gcsUpdateOfficeConRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsUpdateOfficeCon + 'OfficeName=' + aString1 + '&IP=' + aString2 +
      '&Port=' + aString3;

  Self.URL := Self.ConnectionString;
  Self.ServerString := aOffice.ConnectionString + IntToStr(aOffice.Port);
  Self.OnError := Self.HTTPRequestError;
  Self.OnTimeout := Self.ResponseTimeOut;
end;

{ Connection string requiring 1 string parameter }
procedure TclsLogicHTTPRequest.SetConnectionString(aOffice: TclsOfficeInfo;
  asRequestType, aString: string);
begin
  if asRequestType = gcsGetCodesRequest then
    Self.ConnectionString := aOffice.ConnectionString + IntToStr(aOffice.Port)
      + gcsGetCodes + 'CodeType=' + aString;

  Self.URL := Self.ConnectionString;
  Self.ServerString := aOffice.ConnectionString + IntToStr(aOffice.Port);
  Self.OnError := Self.HTTPRequestError;
  Self.OnTimeout := Self.ResponseTimeOut;
end;

procedure TclsLogicHTTPRequest.ShowErrorDialog(aErrorMessage: string);
var
  sDialogText: string;
begin
  sDialogText := aErrorMessage;

  ShowMessage(sDialogText);
end;

function APIDateStringToDate(aDateString: string): TDateTime;
var
  fs: TFormatSettings;
begin
  fs := TFormatSettings.Create;
  fs.DateSeparator := '-';
  fs.ShortDateFormat := 'yyyy-MM-dd';
  fs.TimeSeparator := ':';
  fs.ShortTimeFormat := 'hh:mm';
  try
    Result := StrToDateTime(aDateString, fs);
  except
    console.log('invalid date format: ' + aDateString);
  end;
end;

procedure SendLogMessage(aOffice: TclsOfficeInfo; aLogMessage: String);
var
  LogHTTPRequest: TclsLogicHTTPRequest;
  sPostJSON: string;
begin
  LogHTTPRequest := TclsLogicHTTPRequest.Create(nil);
  try
     LogHTTPRequest.SetConnectionString(aOffice, gcsInsertLogRequest);
     LogHTTPRequest.AddHeaders(aOffice.OfficeSequence, aOffice.UserID,
      gciModuleIntelliOffice, aOffice.APIToken, aOffice.APIPassword);
     LogHTTPRequest.Command := httpPost;

     sPostJSON := '{ "OfficeSequence" : ' + IntToStr(aOffice.OfficeSequence) +
      ', "UserID" : ' + IntToStr(aOffice.UserID) + ', "LogMessage" : "' +
      aLogMessage + '" } ';
     LogHTTPRequest.Headers.AddPair('Content-Type', 'application/json');
     LogHTTPRequest.PostData := sPostJSON;

    LogHTTPRequest.Execute(
    procedure(AResponse: string; AReq: TJSXMLHttpRequest)
    begin
      if AReq.Status = 200 then
      begin
        Console.Log('Server log updated successfully with message :' + aLogMessage);
      end
      else
      begin
        LogHTTPRequest.ProcessResponseError(AResponse);
      end;
    end);
  finally
    LogHTTPRequest.Free;
  end;
end;

Function ProvidersFromJSON(asJSON: string; aiOffSeq: integer): TclsOfficeProviders;
var
  jsProvider: TJSObject;
  jsAryProviders: TJSArray;
  tProvider: TclsApptSchedProvider;
  i: Integer;
begin
  Result := TclsOfficeProviders.Create;
  Result.lstOfficeProviders := tlstProviders.Create;
  try
    jsAryProviders := TJSArray(TJSObject(TJSJSON.parse(asJSON))['value']);
    for i := 0 to jsAryProviders.Length - 1  do
    begin
      tProvider := TclsApptSchedProvider.Create;
      jsProvider := TJSObject(jsAryProviders[i]);

      With tProvider Do
      begin
        IsActive := StrToBool(string(jsProvider[gcsIsActive]));
        ColumnSequence := StrToInt(string(jsProvider[gcsColumnSequence]));
        Provider := string(jsProvider[gcsProvider]);
        Name := string(jsProvider[gcsName]);
        ShortName := string(jsProvider[gcsShortName]);
        IsHygienist := StrToBool(string(jsProvider[gcsIsHygienist]));
        ColumnColor := StrToInt(string(jsProvider[gcsColumnColor]));
        OfficeSequence := aiOffSeq;
      end;

      Result.lstOfficeProviders.Add(tProvider);
    end;
  finally
    tProvider.Free;
  end;
end;

end.
