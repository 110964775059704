unit UnitMain;

interface

uses

  System.SysUtils,
  System.Classes,
  System.Math,
  System.Types,
  System.DateUtils,

  JSDelphiSystem,
  JS,
  Web,

  XData.Web.Client,
  XData.Web.Connection,

  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls,
  WEBLib.WebTools,
  WEBLib.JSON,

  Vcl.Controls,
  Vcl.StdCtrls,
  VCL.TMSFNCCustomControl,
  VCL.TMSFNCWebBrowser,
  VCL.TMSFNCCustomWEBControl,
  WEBLib.DropDown,
  WEBLib.ExtCtrls,
  WEBLib.ComCtrls;

type

  TFormMain = class(TForm)

    divHost: THTMLDiv;
    ServerConn: TXDataWebConnection;

    [async] procedure LoadForm(Form: String); async;
    [async] procedure DatabaseConnect; async;

    procedure LoadAppointmentForm(Connection, FailedConnection: String; Sequence, Port: Integer; Global: Boolean; Treatment: JSValue);
    procedure LoadPatientForm(AppointmentInfo, Day: JSValue);
    function SelectTreatment: JSValue;

    [async] procedure WebFormCreate(Sender: TObject); async;

  private

    { Private declarations }

  public

    { Public declarations }

    CurrentForm: TForm;
    ServerName, CurrentFormName, ConnectionString, FailedConnectionString: String;
    OfficeSequence, ServerPort: Integer;
    GlobalServer: Boolean;
    TreatmentID, TimeSlots, Appointment, SelectedDay, FormattedDay: JSValue;

  protected procedure LoadDFMValues; override; end;

var
  FormMain: TFormMain;

implementation

{$R *.dfm}

uses
  UnitTreatment, UnitAppointment, UnitPatient;

// Replace old form and Load each new form as a component in the app:
procedure TFormMain.LoadForm(Form: String);
var
  ElapsedTime: TDateTime;
  ValidForm: Boolean;
  procedure AfterCreate(AForm: TObject);
  begin
    console.log('Load Form: ' + AForm.ClassName + ' Loaded (' + IntToStr(MillisecondsBetween(Now, ElapsedTime)) + 'ms)');
  end;
begin
  // Time this Action:
  ElapsedTime := Now;

  CurrentFormName := Form;

  // Close the Current Form:
  if Assigned(CurrentForm) then
  begin
    CurrentForm.Close;
    CurrentForm.Free;
    {$IFNDEF WIN32}
    asm
      divHost.replaceChildren();
    end;
    {$ENDIF}
  end;

  // Treatment Type Form:
  if Form = 'FormTreatment' then
  begin
    {$IFNDEF WIN32}
    asm
      this.SelectedDay = (new Date());
//      this.SelectedDay.setTime(this.SelectedDay.getTime() + this.SelectedDay.getTimezoneOffset() * 60 * 1000);
      this.SelectedDay = this.SelectedDay.toISOString().slice(0, 10);
      this.FormattedDay = this.SelectedDay.split("-");
      const temp = this.FormattedDay[0];
      this.FormattedDay[0] = this.FormattedDay[2];
      this.FormattedDay[2] = temp;
      this.FormattedDay = this.FormattedDay.join("/");
    end;
    {$ENDIF}
    CurrentForm := TFormTreatment.CreateNew(divHost.ElementID, @AfterCreate)
  end
  // Appointment Form:
  else if Form = 'FormAppointment' then CurrentForm := TFormAppointment.CreateNew(divHost.ElementID, @AfterCreate)
  
  // Patient Info Form:
  else if Form = 'FormPatient' then CurrentForm := TFormPatient.CreateNew(divHost.ElementID, @AfterCreate)
  
  // Exception for Invalid Forms:
  else       
  begin
    CurrentFormName := 'Invalid Form';
    if Form <> 'Clear' then divHost.HTML.Text := 'ERROR: Form Not Found (' + Form + ')';
  end;
end;

// Connect to the the Database:
procedure TFormMain.DatabaseConnect;
begin
  if not(ServerConn.Connected) then
  begin
    // Assign the server URL:
    ServerName := 'http://localhost:2001/tms/xdata';
//    ServerName := 'http://198.251.68.3:2001/tms/xdata';
    ServerConn.URL := ServerName;
    // Try and establish a connection to the server:
    try
      await( ServerConn.OpenAsync );
    except on E: Exception do
      begin
        console.log('Error: [ ' + E.ClassName + ' ] ' + E.Message);
        raise;
      end;
    end;
  end;
end;

// Load appointment form with id and timeslots:
procedure TFormMain.LoadAppointmentForm(Connection, FailedConnection: String; Sequence, Port: Integer; Global: Boolean; Treatment: JSValue);
begin
  TreatmentID := Treatment;
  ConnectionString := Connection;
  ServerPort := Port;
  OfficeSequence := Sequence;
  GlobalServer := Global;
  if GlobalServer then FailedConnectionString := FailedConnection;
  LoadForm('FormAppointment');
end;

// Load patient form with previous information and date and time, provider, and appointment duration:
procedure TFormMain.LoadPatientForm(AppointmentInfo, Day: JSValue);
begin
  {$IFNDEF WIN32}
  asm
    this.SelectedDay = new Date(Day).toISOString().slice(0, 10);
    this.FormattedDay = this.SelectedDay.split("-");
    const temp = this.FormattedDay[0];
    this.FormattedDay[0] = this.FormattedDay[2];
    this.FormattedDay[2] = temp;
    this.FormattedDay = this.FormattedDay.join("/");
  end;
  {$ENDIF}
  Appointment := AppointmentInfo;
  LoadForm('FormPatient');
end;

function TFormMain.SelectTreatment: JSValue;
begin
  Result := TreatmentID;
end;

procedure TFormMain.WebFormCreate(Sender: TObject);
begin
//  await(DatabaseConnect);
  CurrentFormName := 'Initializing';
  LoadForm('FormTreatment');
end;

procedure TFormMain.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divHost := THTMLDiv.Create('divHost');
  ServerConn := TXDataWebConnection.Create(Self);

  divHost.BeforeLoadDFMValues;
  ServerConn.BeforeLoadDFMValues;
  try
    Name := 'FormMain';
    Width := 1193;
    Height := 705;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divHost.SetParentComponent(Self);
    divHost.Name := 'divHost';
    divHost.Left := 0;
    divHost.Top := 0;
    divHost.Width := 472;
    divHost.Height := 640;
    divHost.Margins.Left := 0;
    divHost.Margins.Top := 0;
    divHost.Margins.Right := 0;
    divHost.Margins.Bottom := 0;
    divHost.HeightStyle := ssAuto;
    divHost.WidthStyle := ssAuto;
    divHost.ChildOrder := 1;
    divHost.ElementPosition := epIgnore;
    divHost.ElementFont := efCSS;
    divHost.Role := '';
    ServerConn.SetParentComponent(Self);
    ServerConn.Name := 'ServerConn';
    ServerConn.Left := 208;
    ServerConn.Top := 560;
  finally
    divHost.AfterLoadDFMValues;
    ServerConn.AfterLoadDFMValues;
  end;
end;

end.