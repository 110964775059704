unit UnitConfirm;

interface

uses

  System.SysUtils,
  System.Classes,
  System.Math,
  System.Types,
  System.DateUtils,
  Generics.Collections,

  JSDelphiSystem,
  JS,
  Web,

  XData.Web.Client,
  XData.Web.Connection,

  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls,
  WEBLib.WebTools,
  WEBLib.JSON,
  WEBLib.REST,

  Vcl.Controls,
  Vcl.StdCtrls,
  VCL.TMSFNCCustomControl,
  VCL.TMSFNCWebBrowser,
  VCL.TMSFNCCustomWEBControl,
  WEBLib.DropDown,
  WEBLib.ExtCtrls,
  WEBLib.ComCtrls,

  Types_Vars_API,
  Types_Vars_Security;

type

  TFormConfirm = class(TForm)

    divPopupConfirm: THTMLDiv;
    divPopupAppoint: THTMLDiv;
    divResponse: THTMLDiv;
    divPopupPatientInfo: THTMLDiv;
    divPopupFirstName: THTMLDiv;
    divPopupLastName: THTMLDiv;
    divPopupEmail: THTMLDiv;
    divPopupPhone: THTMLDiv;
    divPopupNewPatient: THTMLDiv;
    divPopupAppointInfo: THTMLDiv;
    divPopupOfficeInfo: THTMLDiv;
    spanPopupOffice: THTMLSpan;
    spanPopupTreatment: THTMLSpan;
    divPopupAppointment: THTMLDiv;
    divPopupDuration: THTMLDiv;
    spanPopupDurationHeader: THTMLSpan;
    spanPopupDuration: THTMLSpan;
    divPopupDateTime: THTMLDiv;
    spanPopupDateTimeHeader: THTMLSpan;
    spanPopupDateTime: THTMLSpan;
    btnPopupCancel: TButton;
    btnPopupConfirm: TButton;
    divPopupProvider: THTMLDiv;
    spanPopupProviderHeader: THTMLSpan;
    spanPopupProvider: THTMLSpan;
    divLoading: THTMLDiv;
    spanLoader: THTMLSpan;
    spanLoadText: THTMLSpan;

    procedure btnPopupCancelClick(Sender: TObject);
    [async] procedure btnPopupConfirmClick(Sender: TObject); async;

    [async] procedure SendPatientData(Data: JSValue); async;

    procedure WebFormCreate(Sender: TObject);
    procedure WebFormCloseQuery(Sender: TObject; var CanClose: Boolean);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);

  private

    { Private declarations }

  public

    { Public declarations }

    ConnectionString, FirstName, LastName, Email, Phone, IsNewPatient: String;
    CloseForm, GoToTreatments: Boolean;
    TreatmentInfo, AppointmentInfo: JSValue;

  protected procedure LoadDFMValues; override; end;

var
  FormConfirm: TFormConfirm;

implementation

{$R *.dfm}

uses
  UnitMain, UnitPatient;

// Close the modal with no changes if the cancel button is clicked:
procedure TFormConfirm.btnPopupCancelClick(Sender: TObject);
begin
  CloseForm := True;
  ModalResult := mrClose;
end;

// Disable input Submit patient info to whichever server the app is connected to:
procedure TFormConfirm.btnPopupConfirmClick(Sender: TObject);
var
  Data: JSValue;
begin
  {$IFNDEF WIN32}
  asm
    setTimeout(() => {
      $("#divLoading").addClass("show");
    }, 250);
  end;
  {$ENDIF}
  await(SendPatientData(AppointmentInfo));
end;

// Send Patient Data to Database to book appointment:
procedure TFormConfirm.SendPatientData(Data: JSValue);
var
  HTTPRequest: TclsLogicHTTPRequest;
  req: TJSXMLHttpRequest;
  Office: TclsOfficeInfo;
  PostData: String;
begin
  HTTPRequest := TclsLogicHTTPRequest.Create(nil);
  Office := TclsOfficeInfo.Create;
  Office.OfficeSequence := FormMain.OfficeSequence;
  Office.UserID := 1;
  Office.APIToken := 'Yup, this is totally a real token';
  Office.APIPassword := 'ThoronWeilder11';
  Office.ConnectionString := ConnectionString;
  Office.Port := FormMain.ServerPort;
  HTTPRequest.Command := httpPost;
  {$IFNDEF WIN32}
  asm
    PostData = JSON.stringify(Data);
  end;
  {$ENDIF}
  HTTPRequest.PostData := PostData;
  try
    HTTPRequest.Headers.AddPair('Content-Type', 'application/json');
    HTTPRequest.AddHeaders(Office.OfficeSequence, Office.UserID,
      gciModuleIntellieForm, Office.APIToken, Office.APIPassword);
    HTTPRequest.URL := ConnectionString + IntToStr(Office.Port) + '/Data/Booking/SendBooking';
    req := await(TJSXMLHttpRequest, HTTPRequest.Perform());
    if req.Status = 200 then
    begin
      // On successful request
      {$IFNDEF WIN32}
      asm
        // Play the loading complete animation:
        $("#spanLoader").addClass("complete");
        setTimeout(() => {
          $("#divPopupConfirm").css("display", "none");
          $("#spanLoader").remove();
          $("#spanLoadText").addClass("success");
          $("#load-text").text("Booked Successfully");
          $(".confirm").addClass("minimize");
          $("#divLoading").append(`
            <div id="divLoadContainer" class="load-container">
              <span id="spanNewAppointment" class="back">
                <h5>Book another appointment</h5>
              </span>
            </div>
          `);
          $("#spanNewAppointment").on("click", e => {
            e.preventDefault;
            this.GoToTreatments = true;
            this.CloseForm = true;
            this.SetModalResult(1);
          });
        }, 2500);
        setTimeout(() => {
          $("#spanNewAppointment").css("opacity", "1");
        }, 4000);
      end;
      {$ENDIF}
    end
    else
    begin
      // Error Handling:
      HTTPRequest.ProcessResponseError(String(req.response));
      {$IFNDEF WIN32}
      asm
        // Play the loading complete animation:
        $("#spanLoader").addClass("complete");
        setTimeout(() => {
          $("#divPopupConfirm").css("display", "none");
          $("#spanLoader").remove();
          $("#spanLoadText").addClass("failure");
          $("#load-text").text("Booking failed. Please try again.");
          $(".confirm").addClass("minimize");
          $("#divLoading").append(`
            <div id="divLoadContainer" class="load-container">
              <span id="spanCancel" class="back">
                <h5>Cancel Booking</h5>
              </span>
              <span id="spanResubmit" class="back">
                <h5>Try Resubmitting</h5>
              </span>
            </div>
          `);
          $("#spanCancel").on("click", e => {
            e.preventDefault;
            this.btnPopupCancelClick(null);
          });
          $("#spanResubmit").on("click", e => {
            e.preventDefault;
          });
        }, 2500);
        setTimeout(() => {
          $("#spanCancel").css("opacity", "1");
          $("#spanResubmit").css("opacity", "1");
        }, 4000);
      end;
      {$ENDIF}
    end;
  finally
    HTTPRequest.Free;
  end;
end;

// Basically make the form unclosable unless the cancel button or treatments page link is clicked:
procedure TFormConfirm.WebFormCloseQuery(Sender: TObject; var CanClose: Boolean);
begin
  CanClose := CloseForm;
end;

// Once closed, load the treatment form again:
procedure TFormConfirm.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
  if (GoToTreatments) then FormMain.LoadForm('FormTreatment');
end;

// Display all information entered by the patient:
procedure TFormConfirm.WebFormCreate(Sender: TObject);
begin
  // Display all the patient info:
  CloseForm := False;
  ConnectionString := FormMain.ConnectionString;
  {$IFNDEF WIN32}
  asm
    const firstName = this.AppointmentInfo.FirstName;
    const lastName = this.AppointmentInfo.LastName;
    const email = this.AppointmentInfo.Email;
    const phone = this.AppointmentInfo.Phone;
    const isNewPatient = this.AppointmentInfo.IsNewPatient ? "Yes" : "No";
    const day = this.AppointmentInfo.AppointDate;
    let displayDay = new Date(day);
    displayDay.setTime(displayDay.getTime() + displayDay.getTimezoneOffset() * 60 * 1000);
    displayDay = displayDay.toString().split(" ").slice(0, 4).join(" ");
    const minutes = this.AppointmentInfo.TimeSlot % 60 === 0 ? ":00" : `:${(this.AppointmentInfo.TimeSlot % 60)}`;
    const hour = (this.AppointmentInfo.TimeSlot - Number(minutes[1] + minutes[2])) / 60 > 12 ? ((this.AppointmentInfo.TimeSlot - Number(minutes[1] + minutes[2])) / 60) - 12 : (this.AppointmentInfo.TimeSlot - Number(minutes[1] + minutes[2])) / 60;
    const time = `${hour}${minutes} ${(this.AppointmentInfo.TimeSlot >= 720 ? "pm" : "am")}`;
    const provider = this.AppointmentInfo.Provider;
    const office = this.TreatmentInfo.office;
    const name = this.TreatmentInfo.name;
    const interval = this.TreatmentInfo.interval;
    const unit = this.TreatmentInfo.unit;
    $("#popup-first-name").text(firstName);
    $("#popup-last-name").text(lastName);
    $("#popup-email").text(email);
    $("#popup-phone").text(phone);
    $("#popup-new-patient").text(isNewPatient);
    $("#spanPopupOffice").html(`<h6>${office}</h6>`);
    $("#spanPopupTreatment").html(`<h3>${name}</h3>`);
    $("#spanPopupDuration").text(`${(interval * unit)} Mins`);
    $("#spanPopupDateTime").text(`${displayDay} [ ${time} ]`);
    $("#spanPopupProvider").text(`${provider.Name} [ ${provider.Provider} ]`);

    this.AppointmentInfo.AppointDate = new Date(day).toISOString().slice(0, 10);
    this.AppointmentInfo.Provider = provider.Provider;
  end;
  {$ENDIF}
end;

procedure TFormConfirm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divPopupConfirm := THTMLDiv.Create('divPopupConfirm');
  divPopupAppoint := THTMLDiv.Create('divPopupAppoint');
  divPopupPatientInfo := THTMLDiv.Create('divPopupPatientInfo');
  divPopupFirstName := THTMLDiv.Create('divPopupFirstName');
  divPopupLastName := THTMLDiv.Create('divPopupLastName');
  divPopupEmail := THTMLDiv.Create('divPopupEmail');
  divPopupPhone := THTMLDiv.Create('divPopupPhone');
  divPopupNewPatient := THTMLDiv.Create('divPopupNewPatient');
  divPopupAppointInfo := THTMLDiv.Create('divPopupAppointInfo');
  divPopupOfficeInfo := THTMLDiv.Create('divPopupOfficeInfo');
  spanPopupOffice := THTMLSpan.Create('spanPopupOffice');
  spanPopupTreatment := THTMLSpan.Create('spanPopupTreatment');
  divPopupAppointment := THTMLDiv.Create('divPopupAppointment');
  divPopupDuration := THTMLDiv.Create('divPopupDuration');
  spanPopupDurationHeader := THTMLSpan.Create('spanPopupDurationHeader');
  spanPopupDuration := THTMLSpan.Create('spanPopupDuration');
  divPopupDateTime := THTMLDiv.Create('divPopupDateTime');
  spanPopupDateTimeHeader := THTMLSpan.Create('spanPopupDateTimeHeader');
  spanPopupDateTime := THTMLSpan.Create('spanPopupDateTime');
  divPopupProvider := THTMLDiv.Create('divPopupProvider');
  spanPopupProviderHeader := THTMLSpan.Create('spanPopupProviderHeader');
  spanPopupProvider := THTMLSpan.Create('spanPopupProvider');
  divResponse := THTMLDiv.Create('divPopupResponse');
  btnPopupCancel := TButton.Create('btnPopupCancel');
  btnPopupConfirm := TButton.Create('btnPopupConfirm');
  divLoading := THTMLDiv.Create('divLoading');
  spanLoader := THTMLSpan.Create('spanLoader');
  spanLoadText := THTMLSpan.Create('spanLoadText');

  divPopupConfirm.BeforeLoadDFMValues;
  divPopupAppoint.BeforeLoadDFMValues;
  divPopupPatientInfo.BeforeLoadDFMValues;
  divPopupFirstName.BeforeLoadDFMValues;
  divPopupLastName.BeforeLoadDFMValues;
  divPopupEmail.BeforeLoadDFMValues;
  divPopupPhone.BeforeLoadDFMValues;
  divPopupNewPatient.BeforeLoadDFMValues;
  divPopupAppointInfo.BeforeLoadDFMValues;
  divPopupOfficeInfo.BeforeLoadDFMValues;
  spanPopupOffice.BeforeLoadDFMValues;
  spanPopupTreatment.BeforeLoadDFMValues;
  divPopupAppointment.BeforeLoadDFMValues;
  divPopupDuration.BeforeLoadDFMValues;
  spanPopupDurationHeader.BeforeLoadDFMValues;
  spanPopupDuration.BeforeLoadDFMValues;
  divPopupDateTime.BeforeLoadDFMValues;
  spanPopupDateTimeHeader.BeforeLoadDFMValues;
  spanPopupDateTime.BeforeLoadDFMValues;
  divPopupProvider.BeforeLoadDFMValues;
  spanPopupProviderHeader.BeforeLoadDFMValues;
  spanPopupProvider.BeforeLoadDFMValues;
  divResponse.BeforeLoadDFMValues;
  btnPopupCancel.BeforeLoadDFMValues;
  btnPopupConfirm.BeforeLoadDFMValues;
  divLoading.BeforeLoadDFMValues;
  spanLoader.BeforeLoadDFMValues;
  spanLoadText.BeforeLoadDFMValues;
  try
    Name := 'FormConfirm';
    Width := 1085;
    Height := 651;
    ElementClassName := 'confirm';
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    Shadow := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCloseQuery', 'WebFormCloseQuery');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divPopupConfirm.SetParentComponent(Self);
    divPopupConfirm.Name := 'divPopupConfirm';
    divPopupConfirm.Left := 8;
    divPopupConfirm.Top := 8;
    divPopupConfirm.Width := 1069;
    divPopupConfirm.Height := 635;
    divPopupConfirm.ElementClassName := 'confirm-container';
    divPopupConfirm.HeightStyle := ssAuto;
    divPopupConfirm.WidthStyle := ssAuto;
    divPopupConfirm.ElementPosition := epIgnore;
    divPopupConfirm.ElementFont := efCSS;
    divPopupConfirm.Role := '';
    divPopupAppoint.SetParentComponent(divPopupConfirm);
    divPopupAppoint.Name := 'divPopupAppoint';
    divPopupAppoint.Left := 3;
    divPopupAppoint.Top := 3;
    divPopupAppoint.Width := 1063;
    divPopupAppoint.Height := 502;
    divPopupAppoint.ElementClassName := 'appoint-container';
    divPopupAppoint.HeightStyle := ssAuto;
    divPopupAppoint.WidthStyle := ssAuto;
    divPopupAppoint.ElementPosition := epIgnore;
    divPopupAppoint.ElementFont := efCSS;
    divPopupAppoint.Role := '';
    divPopupPatientInfo.SetParentComponent(divPopupAppoint);
    divPopupPatientInfo.Name := 'divPopupPatientInfo';
    divPopupPatientInfo.Left := 3;
    divPopupPatientInfo.Top := 5;
    divPopupPatientInfo.Width := 601;
    divPopupPatientInfo.Height := 494;
    divPopupPatientInfo.ElementClassName := 'popup-patient-holder';
    divPopupPatientInfo.HeightStyle := ssAuto;
    divPopupPatientInfo.WidthStyle := ssAuto;
    divPopupPatientInfo.ElementPosition := epIgnore;
    divPopupPatientInfo.ElementFont := efCSS;
    divPopupPatientInfo.Role := '';
    divPopupFirstName.SetParentComponent(divPopupPatientInfo);
    divPopupFirstName.Name := 'divPopupFirstName';
    divPopupFirstName.Left := 27;
    divPopupFirstName.Top := 27;
    divPopupFirstName.Width := 100;
    divPopupFirstName.Height := 41;
    divPopupFirstName.HeightStyle := ssAuto;
    divPopupFirstName.WidthStyle := ssAuto;
    divPopupFirstName.ElementPosition := epIgnore;
    divPopupFirstName.ElementFont := efCSS;
    divPopupFirstName.HTML.BeginUpdate;
    try
      divPopupFirstName.HTML.Clear;
      divPopupFirstName.HTML.Add('<h6 class="grey">First Name</h6>');
      divPopupFirstName.HTML.Add('<h4 id="popup-first-name"></h4>');
    finally
      divPopupFirstName.HTML.EndUpdate;
    end;
    divPopupFirstName.Role := '';
    divPopupLastName.SetParentComponent(divPopupPatientInfo);
    divPopupLastName.Name := 'divPopupLastName';
    divPopupLastName.Left := 27;
    divPopupLastName.Top := 120;
    divPopupLastName.Width := 100;
    divPopupLastName.Height := 41;
    divPopupLastName.HeightStyle := ssAuto;
    divPopupLastName.WidthStyle := ssAuto;
    divPopupLastName.ChildOrder := 1;
    divPopupLastName.ElementPosition := epIgnore;
    divPopupLastName.ElementFont := efCSS;
    divPopupLastName.HTML.BeginUpdate;
    try
      divPopupLastName.HTML.Clear;
      divPopupLastName.HTML.Add('<h6 class="grey">Last Name</h6>');
      divPopupLastName.HTML.Add('<h4 id="popup-last-name"></h4>');
    finally
      divPopupLastName.HTML.EndUpdate;
    end;
    divPopupLastName.Role := '';
    divPopupEmail.SetParentComponent(divPopupPatientInfo);
    divPopupEmail.Name := 'divPopupEmail';
    divPopupEmail.Left := 27;
    divPopupEmail.Top := 224;
    divPopupEmail.Width := 100;
    divPopupEmail.Height := 41;
    divPopupEmail.HeightStyle := ssAuto;
    divPopupEmail.WidthStyle := ssAuto;
    divPopupEmail.ChildOrder := 1;
    divPopupEmail.ElementPosition := epIgnore;
    divPopupEmail.ElementFont := efCSS;
    divPopupEmail.HTML.BeginUpdate;
    try
      divPopupEmail.HTML.Clear;
      divPopupEmail.HTML.Add('<h6 class="grey">Email Address</h6>');
      divPopupEmail.HTML.Add('<h4 id="popup-email"></h4>');
    finally
      divPopupEmail.HTML.EndUpdate;
    end;
    divPopupEmail.Role := '';
    divPopupPhone.SetParentComponent(divPopupPatientInfo);
    divPopupPhone.Name := 'divPopupPhone';
    divPopupPhone.Left := 27;
    divPopupPhone.Top := 320;
    divPopupPhone.Width := 100;
    divPopupPhone.Height := 41;
    divPopupPhone.HeightStyle := ssAuto;
    divPopupPhone.WidthStyle := ssAuto;
    divPopupPhone.ChildOrder := 1;
    divPopupPhone.ElementPosition := epIgnore;
    divPopupPhone.ElementFont := efCSS;
    divPopupPhone.HTML.BeginUpdate;
    try
      divPopupPhone.HTML.Clear;
      divPopupPhone.HTML.Add('<h6 class="grey">Phone Number</h6>');
      divPopupPhone.HTML.Add('<h4 id="popup-phone"></h4>');
    finally
      divPopupPhone.HTML.EndUpdate;
    end;
    divPopupPhone.Role := '';
    divPopupNewPatient.SetParentComponent(divPopupPatientInfo);
    divPopupNewPatient.Name := 'divPopupNewPatient';
    divPopupNewPatient.Left := 27;
    divPopupNewPatient.Top := 424;
    divPopupNewPatient.Width := 100;
    divPopupNewPatient.Height := 41;
    divPopupNewPatient.HeightStyle := ssAuto;
    divPopupNewPatient.WidthStyle := ssAuto;
    divPopupNewPatient.ChildOrder := 1;
    divPopupNewPatient.ElementPosition := epIgnore;
    divPopupNewPatient.ElementFont := efCSS;
    divPopupNewPatient.HTML.BeginUpdate;
    try
      divPopupNewPatient.HTML.Clear;
      divPopupNewPatient.HTML.Add('<h6 class="grey">Are you a New Patient</h6>');
      divPopupNewPatient.HTML.Add('<h4 id="popup-new-patient"></h4>');
    finally
      divPopupNewPatient.HTML.EndUpdate;
    end;
    divPopupNewPatient.Role := '';
    divPopupAppointInfo.SetParentComponent(divPopupAppoint);
    divPopupAppointInfo.Name := 'divPopupAppointInfo';
    divPopupAppointInfo.Left := 610;
    divPopupAppointInfo.Top := 3;
    divPopupAppointInfo.Width := 450;
    divPopupAppointInfo.Height := 494;
    divPopupAppointInfo.ElementClassName := 'popup-appoint-holder';
    divPopupAppointInfo.HeightStyle := ssAuto;
    divPopupAppointInfo.WidthStyle := ssAuto;
    divPopupAppointInfo.ChildOrder := 1;
    divPopupAppointInfo.ElementPosition := epIgnore;
    divPopupAppointInfo.ElementFont := efCSS;
    divPopupAppointInfo.Role := '';
    divPopupOfficeInfo.SetParentComponent(divPopupAppointInfo);
    divPopupOfficeInfo.Name := 'divPopupOfficeInfo';
    divPopupOfficeInfo.Left := 3;
    divPopupOfficeInfo.Top := 3;
    divPopupOfficeInfo.Width := 444;
    divPopupOfficeInfo.Height := 126;
    divPopupOfficeInfo.ElementClassName := 'popup-office-info-holder';
    divPopupOfficeInfo.HeightStyle := ssAuto;
    divPopupOfficeInfo.WidthStyle := ssAuto;
    divPopupOfficeInfo.ElementPosition := epIgnore;
    divPopupOfficeInfo.ElementFont := efCSS;
    divPopupOfficeInfo.Role := '';
    spanPopupOffice.SetParentComponent(divPopupOfficeInfo);
    spanPopupOffice.Name := 'spanPopupOffice';
    spanPopupOffice.Left := 3;
    spanPopupOffice.Top := 3;
    spanPopupOffice.Width := 438;
    spanPopupOffice.Height := 45;
    spanPopupOffice.HeightStyle := ssAuto;
    spanPopupOffice.WidthStyle := ssAuto;
    spanPopupOffice.ElementPosition := epIgnore;
    spanPopupOffice.ElementFont := efCSS;
    spanPopupOffice.Role := '';
    spanPopupTreatment.SetParentComponent(divPopupOfficeInfo);
    spanPopupTreatment.Name := 'spanPopupTreatment';
    spanPopupTreatment.Left := 3;
    spanPopupTreatment.Top := 54;
    spanPopupTreatment.Width := 438;
    spanPopupTreatment.Height := 69;
    spanPopupTreatment.HeightStyle := ssAuto;
    spanPopupTreatment.WidthStyle := ssAuto;
    spanPopupTreatment.ChildOrder := 1;
    spanPopupTreatment.ElementPosition := epIgnore;
    spanPopupTreatment.ElementFont := efCSS;
    spanPopupTreatment.Role := '';
    divPopupAppointment.SetParentComponent(divPopupAppointInfo);
    divPopupAppointment.Name := 'divPopupAppointment';
    divPopupAppointment.Left := 3;
    divPopupAppointment.Top := 132;
    divPopupAppointment.Width := 444;
    divPopupAppointment.Height := 359;
    divPopupAppointment.ElementClassName := 'popup-appoint-info-holder';
    divPopupAppointment.HeightStyle := ssAuto;
    divPopupAppointment.WidthStyle := ssAuto;
    divPopupAppointment.ChildOrder := 1;
    divPopupAppointment.ElementPosition := epIgnore;
    divPopupAppointment.ElementFont := efCSS;
    divPopupAppointment.Role := '';
    divPopupDuration.SetParentComponent(divPopupAppointment);
    divPopupDuration.Name := 'divPopupDuration';
    divPopupDuration.Left := 3;
    divPopupDuration.Top := 3;
    divPopupDuration.Width := 438;
    divPopupDuration.Height := 118;
    divPopupDuration.ElementClassName := 'popup-holder';
    divPopupDuration.HeightStyle := ssAuto;
    divPopupDuration.WidthStyle := ssAuto;
    divPopupDuration.ElementPosition := epIgnore;
    divPopupDuration.ElementFont := efCSS;
    divPopupDuration.Role := '';
    spanPopupDurationHeader.SetParentComponent(divPopupDuration);
    spanPopupDurationHeader.Name := 'spanPopupDurationHeader';
    spanPopupDurationHeader.Left := 3;
    spanPopupDurationHeader.Top := 3;
    spanPopupDurationHeader.Width := 432;
    spanPopupDurationHeader.Height := 41;
    spanPopupDurationHeader.ElementClassName := 'popup-header';
    spanPopupDurationHeader.HeightStyle := ssAuto;
    spanPopupDurationHeader.WidthStyle := ssAuto;
    spanPopupDurationHeader.ElementPosition := epIgnore;
    spanPopupDurationHeader.ElementFont := efCSS;
    spanPopupDurationHeader.HTML.BeginUpdate;
    try
      spanPopupDurationHeader.HTML.Clear;
      spanPopupDurationHeader.HTML.Add('<span class="material-symbols-outlined">hourglass </span><p>Duration</p>');
    finally
      spanPopupDurationHeader.HTML.EndUpdate;
    end;
    spanPopupDurationHeader.Role := '';
    spanPopupDuration.SetParentComponent(divPopupDuration);
    spanPopupDuration.Name := 'spanPopupDuration';
    spanPopupDuration.Left := 3;
    spanPopupDuration.Top := 48;
    spanPopupDuration.Width := 432;
    spanPopupDuration.Height := 67;
    spanPopupDuration.ElementClassName := 'popup-content';
    spanPopupDuration.HeightStyle := ssAuto;
    spanPopupDuration.WidthStyle := ssAuto;
    spanPopupDuration.ChildOrder := 1;
    spanPopupDuration.ElementPosition := epIgnore;
    spanPopupDuration.ElementFont := efCSS;
    spanPopupDuration.Role := '';
    divPopupDateTime.SetParentComponent(divPopupAppointment);
    divPopupDateTime.Name := 'divPopupDateTime';
    divPopupDateTime.Left := 3;
    divPopupDateTime.Top := 124;
    divPopupDateTime.Width := 438;
    divPopupDateTime.Height := 102;
    divPopupDateTime.ElementClassName := 'popup-holder';
    divPopupDateTime.HeightStyle := ssAuto;
    divPopupDateTime.WidthStyle := ssAuto;
    divPopupDateTime.ChildOrder := 1;
    divPopupDateTime.ElementPosition := epIgnore;
    divPopupDateTime.ElementFont := efCSS;
    divPopupDateTime.Role := '';
    spanPopupDateTimeHeader.SetParentComponent(divPopupDateTime);
    spanPopupDateTimeHeader.Name := 'spanPopupDateTimeHeader';
    spanPopupDateTimeHeader.Left := 3;
    spanPopupDateTimeHeader.Top := 3;
    spanPopupDateTimeHeader.Width := 432;
    spanPopupDateTimeHeader.Height := 41;
    spanPopupDateTimeHeader.ElementClassName := 'popup-header';
    spanPopupDateTimeHeader.HeightStyle := ssAuto;
    spanPopupDateTimeHeader.WidthStyle := ssAuto;
    spanPopupDateTimeHeader.ElementPosition := epIgnore;
    spanPopupDateTimeHeader.ElementFont := efCSS;
    spanPopupDateTimeHeader.HTML.BeginUpdate;
    try
      spanPopupDateTimeHeader.HTML.Clear;
      spanPopupDateTimeHeader.HTML.Add('<span class="material-symbols-outlined">calendar_today </span><p>Appointment Time</p>');
    finally
      spanPopupDateTimeHeader.HTML.EndUpdate;
    end;
    spanPopupDateTimeHeader.Role := '';
    spanPopupDateTime.SetParentComponent(divPopupDateTime);
    spanPopupDateTime.Name := 'spanPopupDateTime';
    spanPopupDateTime.Left := 3;
    spanPopupDateTime.Top := 50;
    spanPopupDateTime.Width := 432;
    spanPopupDateTime.Height := 49;
    spanPopupDateTime.ElementClassName := 'popup-content';
    spanPopupDateTime.HeightStyle := ssAuto;
    spanPopupDateTime.WidthStyle := ssAuto;
    spanPopupDateTime.ChildOrder := 1;
    spanPopupDateTime.ElementPosition := epIgnore;
    spanPopupDateTime.ElementFont := efCSS;
    spanPopupDateTime.Role := '';
    divPopupProvider.SetParentComponent(divPopupAppointment);
    divPopupProvider.Name := 'divPopupProvider';
    divPopupProvider.Left := 3;
    divPopupProvider.Top := 232;
    divPopupProvider.Width := 438;
    divPopupProvider.Height := 124;
    divPopupProvider.ElementClassName := 'popup-holder';
    divPopupProvider.HeightStyle := ssAuto;
    divPopupProvider.WidthStyle := ssAuto;
    divPopupProvider.ChildOrder := 2;
    divPopupProvider.ElementPosition := epIgnore;
    divPopupProvider.ElementFont := efCSS;
    divPopupProvider.Role := '';
    spanPopupProviderHeader.SetParentComponent(divPopupProvider);
    spanPopupProviderHeader.Name := 'spanPopupProviderHeader';
    spanPopupProviderHeader.Left := 3;
    spanPopupProviderHeader.Top := 3;
    spanPopupProviderHeader.Width := 432;
    spanPopupProviderHeader.Height := 41;
    spanPopupProviderHeader.ElementClassName := 'popup-header';
    spanPopupProviderHeader.HeightStyle := ssAuto;
    spanPopupProviderHeader.WidthStyle := ssAuto;
    spanPopupProviderHeader.ElementPosition := epIgnore;
    spanPopupProviderHeader.ElementFont := efCSS;
    spanPopupProviderHeader.HTML.BeginUpdate;
    try
      spanPopupProviderHeader.HTML.Clear;
      spanPopupProviderHeader.HTML.Add('<span class="material-symbols-outlined">person</span><p>Provider</p>');
    finally
      spanPopupProviderHeader.HTML.EndUpdate;
    end;
    spanPopupProviderHeader.Role := '';
    spanPopupProvider.SetParentComponent(divPopupProvider);
    spanPopupProvider.Name := 'spanPopupProvider';
    spanPopupProvider.Left := 3;
    spanPopupProvider.Top := 48;
    spanPopupProvider.Width := 432;
    spanPopupProvider.Height := 73;
    spanPopupProvider.ElementClassName := 'popup-content';
    spanPopupProvider.HeightStyle := ssAuto;
    spanPopupProvider.WidthStyle := ssAuto;
    spanPopupProvider.ChildOrder := 1;
    spanPopupProvider.ElementPosition := epIgnore;
    spanPopupProvider.ElementFont := efCSS;
    spanPopupProvider.Role := '';
    divResponse.SetParentComponent(divPopupConfirm);
    divResponse.Name := 'divResponse';
    divResponse.Left := 3;
    divResponse.Top := 511;
    divResponse.Width := 1063;
    divResponse.Height := 121;
    divResponse.ElementClassName := 'response-container';
    divResponse.HeightStyle := ssAuto;
    divResponse.WidthStyle := ssAuto;
    divResponse.ChildOrder := 1;
    divResponse.ElementPosition := epIgnore;
    divResponse.ElementFont := efCSS;
    divResponse.Role := '';
    btnPopupCancel.SetParentComponent(divResponse);
    btnPopupCancel.Name := 'btnPopupCancel';
    btnPopupCancel.Left := 200;
    btnPopupCancel.Top := 48;
    btnPopupCancel.Width := 96;
    btnPopupCancel.Height := 25;
    btnPopupCancel.Caption := 'Cancel';
    btnPopupCancel.ElementClassName := 'btn-cancel';
    btnPopupCancel.ElementFont := efCSS;
    btnPopupCancel.ElementPosition := epIgnore;
    btnPopupCancel.HeightStyle := ssAuto;
    btnPopupCancel.HeightPercent := 100.000000000000000000;
    btnPopupCancel.WidthStyle := ssAuto;
    btnPopupCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnPopupCancel, Self, 'OnClick', 'btnPopupCancelClick');
    btnPopupConfirm.SetParentComponent(divResponse);
    btnPopupConfirm.Name := 'btnPopupConfirm';
    btnPopupConfirm.Left := 816;
    btnPopupConfirm.Top := 48;
    btnPopupConfirm.Width := 96;
    btnPopupConfirm.Height := 25;
    btnPopupConfirm.Caption := 'Confirm';
    btnPopupConfirm.ChildOrder := 1;
    btnPopupConfirm.ElementClassName := 'btn-confirm';
    btnPopupConfirm.ElementFont := efCSS;
    btnPopupConfirm.ElementPosition := epIgnore;
    btnPopupConfirm.HeightStyle := ssAuto;
    btnPopupConfirm.HeightPercent := 100.000000000000000000;
    btnPopupConfirm.WidthStyle := ssAuto;
    btnPopupConfirm.WidthPercent := 100.000000000000000000;
    SetEvent(btnPopupConfirm, Self, 'OnClick', 'btnPopupConfirmClick');
    divLoading.SetParentComponent(Self);
    divLoading.Name := 'divLoading';
    divLoading.Left := 344;
    divLoading.Top := 152;
    divLoading.Width := 100;
    divLoading.Height := 81;
    divLoading.ElementClassName := 'loader-container';
    divLoading.HeightStyle := ssAuto;
    divLoading.WidthStyle := ssAuto;
    divLoading.ChildOrder := 1;
    divLoading.ElementPosition := epIgnore;
    divLoading.ElementFont := efCSS;
    divLoading.Role := '';
    spanLoader.SetParentComponent(divLoading);
    spanLoader.Name := 'spanLoader';
    spanLoader.Left := 0;
    spanLoader.Top := 0;
    spanLoader.Width := 100;
    spanLoader.Height := 41;
    spanLoader.ElementClassName := 'loader';
    spanLoader.HeightStyle := ssAuto;
    spanLoader.WidthStyle := ssAuto;
    spanLoader.ElementPosition := epIgnore;
    spanLoader.ElementFont := efCSS;
    spanLoader.Role := '';
    spanLoadText.SetParentComponent(divLoading);
    spanLoadText.Name := 'spanLoadText';
    spanLoadText.Left := 0;
    spanLoadText.Top := 40;
    spanLoadText.Width := 100;
    spanLoadText.Height := 41;
    spanLoadText.ElementClassName := 'loading-text';
    spanLoadText.HeightStyle := ssAuto;
    spanLoadText.WidthStyle := ssAuto;
    spanLoadText.ChildOrder := 1;
    spanLoadText.ElementPosition := epIgnore;
    spanLoadText.ElementFont := efCSS;
    spanLoadText.HTML.BeginUpdate;
    try
      spanLoadText.HTML.Clear;
      spanLoadText.HTML.Add('<h3 id="load-text">Booking Appointment</h3>');
    finally
      spanLoadText.HTML.EndUpdate;
    end;
    spanLoadText.Role := '';
  finally
    divPopupConfirm.AfterLoadDFMValues;
    divPopupAppoint.AfterLoadDFMValues;
    divPopupPatientInfo.AfterLoadDFMValues;
    divPopupFirstName.AfterLoadDFMValues;
    divPopupLastName.AfterLoadDFMValues;
    divPopupEmail.AfterLoadDFMValues;
    divPopupPhone.AfterLoadDFMValues;
    divPopupNewPatient.AfterLoadDFMValues;
    divPopupAppointInfo.AfterLoadDFMValues;
    divPopupOfficeInfo.AfterLoadDFMValues;
    spanPopupOffice.AfterLoadDFMValues;
    spanPopupTreatment.AfterLoadDFMValues;
    divPopupAppointment.AfterLoadDFMValues;
    divPopupDuration.AfterLoadDFMValues;
    spanPopupDurationHeader.AfterLoadDFMValues;
    spanPopupDuration.AfterLoadDFMValues;
    divPopupDateTime.AfterLoadDFMValues;
    spanPopupDateTimeHeader.AfterLoadDFMValues;
    spanPopupDateTime.AfterLoadDFMValues;
    divPopupProvider.AfterLoadDFMValues;
    spanPopupProviderHeader.AfterLoadDFMValues;
    spanPopupProvider.AfterLoadDFMValues;
    divResponse.AfterLoadDFMValues;
    btnPopupCancel.AfterLoadDFMValues;
    btnPopupConfirm.AfterLoadDFMValues;
    divLoading.AfterLoadDFMValues;
    spanLoader.AfterLoadDFMValues;
    spanLoadText.AfterLoadDFMValues;
  end;
end;

end.
